import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/auxIn/auxInList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  getTimeSegList: () => {
    return get(`${process.env.REACT_APP_API_URL}api/timeSeg/getTimeSegList`, {});
  },
  saveAuxIn: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/auxIn/Edit`, data, {});
  },
};

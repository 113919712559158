import Cookies from 'universal-cookie';
import { RS485AddressCodeFigureType } from '../interface/accessDevice.interface';

export const getOicStorageKey = () => {
  const cookies = new Cookies();
  const authSettings = cookies.get('token');
  if (authSettings) {
    return authSettings;
  }
  return null;
};

export const getOicInfo = () => {
  const key = getOicStorageKey();
  if (key) {
    return key;
  }
  return null;
};

export const decodeJWTToken = (token: string) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
};

export const getToken = () => {
  const oic = getOicInfo();
  if (oic) {
    return {
      Authorization: `Bearer ${oic}`,
      // 'content-type': 'multipart/form-data',
    };
  }
  return {};
};

export const getFirstCapsLetter = (name: string = '') => {
  return name.toUpperCase().split('')[0];
};

export const changeDateTimeFormat = (value: any) => {
  return value
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(value))
    : '';
};

export const changeDateFormat = (value: any) => {
  const date = new Date(value);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};
export const getCurrentDateTimeWithTimezone = () => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Kolkata', // Specify your timezone
  };

  const formattedDate = new Date().toLocaleString('en-GB', options).match(/\d+/g);

  if (formattedDate && formattedDate.length === 6) {
    const [day, month, year, hour, minute, second] = formattedDate;
    return `${year}${month}${day}${hour}${minute}${second}`;
  }

  return '00000000000000';
};

export const fileDownload = (data: any, name: string) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name); // Set file name
  document.body.appendChild(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(url);
  // Clean up the Object URL to release memory
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};
export const getOperateTypeAction = (value: number) => {
  let result = '';
  switch (value) {
    case 1:
      result = 'Issue Card';
      break;
    case 7:
      result = 'Reactivate Lost Card';
      break;
    case 6:
      result = 'Reported Lost Card';
      break;
    case 10:
      result = 'Write management card';
      break;
    case 11:
      result = 'Write Card';
      break;
    case 12:
      result = 'Card Returned';
      break;
    case 13:
      result = 'Card Change';
      break;
    default:
      result = 'Unknown Action';
  }
  return result;
};

// Calculate RS485 Address based on switch states
export const getRS485Value = (switchStates: Record<string, boolean>): number => {
  // Each switch corresponds to a unique value, defined as below:
  const switchValues: any = {
    switch1: 1, // 2^0 = 1
    switch2: 2, // 2^1 = 2
    switch3: 4, // 2^2 = 4
    switch4: 8, // 2^3 = 8
    switch5: 16, // 2^4 = 16
    switch6: 32, // 2^5 = 32
    switch7: 64, // 2^6 = 64
    switch8: 128, // 2^7 = 128
  };

  // Calculate the RS485 address by summing the values of active switches
  return Object.entries(switchStates).reduce((total, [key, isOn]) => {
    return isOn ? total + switchValues[key] : total;
  }, 0);
};

export const getSwitchStatesFromRS485 = (value: any): RS485AddressCodeFigureType => {
  // Create a default state for 8 switches
  const switchStates: RS485AddressCodeFigureType = {
    switch1: false,
    switch2: false,
    switch3: false,
    switch4: false,
    switch5: false,
    switch6: false,
    switch7: false,
    switch8: false,
  };

  // Check the binary representation of the value
  const binaryString = value.toString(2).padStart(8, '0'); // Get binary and pad to 8 digits

  // Update switch states based on binary representation (from right to left)
  Object.keys(switchStates).forEach((key, index) => {
    const isSwitchOn = binaryString[binaryString.length - 1 - index] === '1'; // Rightmost bit represents switch1
    switchStates[key as keyof RS485AddressCodeFigureType] = isSwitchOn;
  });

  return switchStates;
};

// Helper to sort devices based on their online/offline/disabled status
export const sortValuesByStatus = (a: any, b: any): number => {
  if (a.status === 'online' && b.status !== 'online') return -1;
  if (a.status !== 'online' && b.status === 'online') return 1;
  return 0; // Maintain order if both have the same status
};

export function getCurrentMilliseconds() {
  const currentTimeInMillis = Date.now();
  return currentTimeInMillis;
}

export const LoadingProgressCount = (
  initialProgress: any,
  incrementAmount: any,
  intervalDuration: any,
  onUpdate: any,
  onComplete: any,
) => {
  let count = initialProgress; // Start from initial progress

  const interval = setInterval(() => {
    const newProgress = count < 100 ? count + incrementAmount : 100; // Calculate new progress
    onUpdate(newProgress); // Update progress using callback
    count = newProgress; // Update the count

    if (count >= 100) {
      clearInterval(interval); // Stop once count reaches 100
      onComplete(); // Call the completion callback
    }
  }, intervalDuration); // Set interval duration

  // Return a function to clear the interval if needed
  return () => clearInterval(interval);
};

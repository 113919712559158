/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import { AccessByDepartmentInterface } from '../../interface/accessByDepartment.interface';
import accessByPerson from '../../utils/api/AccessRule/accessByPerson';

// Thunk for fetching the department list
export const fetchAccessByPerson = createAsyncThunk(
  'fetchAccessByPerson',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByPerson.getPersonList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
export const fetchPersonLevel = createAsyncThunk(
  'fetchPersonLevel',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByPerson.getPersonLevel(data);
      console.log('Response Data:', response.data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

interface LevelProps {
  equals: boolean;
  id: string;
  levelName: string;
  timeSegName: string;
}
// Initial state for the slice
interface PersonState {
  persons: AccessByDepartmentInterface[]; // List of issued cards
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
  deptId: string;
  levels: LevelProps[];
  levelStatus: string;
  levelTotalDataCount: number;
  levelCurrentPageNo: number;
  levelSearchFilter: any;
}

const initialState: PersonState = {
  persons: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  deptId: '',
  levels: [],
  levelStatus: 'success',
  levelTotalDataCount: 0,
  levelCurrentPageNo: 0,
  levelSearchFilter: {},
};

// Create the slice
export const accessByPersonSlice = createSlice({
  name: 'accessByPerson',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
    deleteLevels: (state, action: PayloadAction<any>) => {
      console.log(action.payload);
      state.levels = state.levels.filter((val: any) => val.code !== action.payload);
      state.levelTotalDataCount -= 1;
    },
    setLevelSearchFilter: (state, action: PayloadAction<any>) => {
      state.levelSearchFilter = action.payload;
    },
    resetLevels: (state) => {
      state.levels = [];
    },
  },
  extraReducers: (builder) => {
    // Handle fetchAccessByPerson actions
    builder
      .addCase(fetchAccessByPerson.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccessByPerson.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.currentPageNo === 1) {
            state.persons = action.payload.data.list;
            state.deptId = action.payload.data.list?.[0]?.id;
          } else {
            state.persons = [...state.persons, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchAccessByPerson.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchPersonLevel.pending, (state) => {
        state.levelStatus = 'loading';
      })
      .addCase(fetchPersonLevel.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.levelTotalDataCount = action.payload.data.size;
          state.levelCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.levelCurrentPageNo === 1) {
            state.levels = action.payload.data.list;
          } else {
            state.levels = [...state.levels, ...action.payload.data.list];
          }
        }
        state.levelStatus = 'success';
      })
      .addCase(fetchPersonLevel.rejected, (state) => {
        state.levelStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setLevelSearchFilter, resetLevels, deleteLevels } =
  accessByPersonSlice.actions;

export default accessByPersonSlice.reducer;

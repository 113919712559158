import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccessIcon, MainMenuIcon, MenuPersonIcon } from '../../assets/svgicons/svgicon';
// AttendanceIcon, ElevatorIcon,VisitorIcon
function Menus() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const menuList = [
    {
      icon: MenuPersonIcon,
      title: t('Personnel'),
      url: '/admin/personnel/personnel/person',
      key: 'personnel',
    },
    {
      icon: AccessIcon,
      title: t('Access'),
      url: '/admin/accessDevice/device',
      key: 'accessDevice',
    },
    // {
    //   icon: AttendanceIcon,
    //   title: t('Attendance'),
    //   key: 'attendance',
    // },
    // {
    //   icon: ElevatorIcon,
    //   title: t('Elevator'),
    //   key: 'elevator',
    // },
    // {
    //   icon: VisitorIcon,
    //   title: t('Visitor'),
    //   key: 'visitor',
    // },
  ];

  const handleClickMenu = (menu: any) => {
    navigate(menu.url);
    setActiveMenu(menu.key);
  };

  useEffect(() => {
    const paramsSplit = location.pathname?.split('/');
    if (activeMenu === '') {
      setActiveMenu(paramsSplit?.[2]);
    }
  }, [location.pathname]);

  return (
    <div className="header-main-menus">
      <div className="menu-icon">
        <MainMenuIcon />
      </div>
      <div className="menu-container">
        <div className="menu-list">
          {menuList.map((menu: any) => (
            <div
              key={menu.key}
              className={activeMenu === menu.key ? 'menu-card active' : 'menu-card'}
              onClick={() => handleClickMenu(menu)}
              role="presentation">
              <div className="icon">
                <menu.icon color={activeMenu === menu.key ? '#FFFFFF' : '#696C6F'} />
              </div>
              <div className="menu-title">{menu.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Menus;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddAntiSelectedDevice from './Add-AntiSelectedDevice';
import ModalBox from '../../../shared-components/modal-box/modal-box';

interface AntiPassbackProps {
  closeModal: () => void;
}
interface Device {
  id: string;
  deviceName: string;
  serialNumber: string;
}
function AddAntiPassback({ closeModal }: AntiPassbackProps) {
  const { t } = useTranslation();
  const tableData: Device[] = [
    {
      id: '0',
      deviceName: 'Test - 1',
      serialNumber: 'CJPM230660002',
    },
    {
      id: '1',
      deviceName: 'Test - 2',
      serialNumber: 'CJPM230660002',
    },
  ];
  const [devices, setDevices] = useState<Device[]>(tableData);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [levelDetails, setLevelDetails] = useState({
    name: '',
    antiPassbackRule: '',
    noOfDoors: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accessinterlock'),
    content: <>hi</>,
  });
  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onTableSwap = (newAvailableData: Device[], newSelectedData: Device[]) => {
    setDevices(newAvailableData); // Update available data
    setSelectedDevices(newSelectedData); // Update selected data
  };
  const tableColumns = [
    { label: t('deviceName'), id: 'deviceName' },
    { label: t('serialNumber'), id: 'serialNumber' },
  ];
  const changeSearch = () => {
    console.log('search');
  };
  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('selecteDevice'),
      content: (
        <AddAntiSelectedDevice
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          onTableSwap={onTableSwap}
        />
      ),
    });
  };

  // const submit = () => {
  //   // Handle submit logic
  //   console.log(levelDetails);
  //   closeModal();
  // };

  return (
    <div className="add-level-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="name"
          name="name"
          label={t('name')}
          type="text"
          value={levelDetails.name}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          id="antiPassbackRule"
          name="antiPassbackRule"
          label={t('antiPassbackRule')}
          value={levelDetails.antiPassbackRule}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            {
              label: 'Anti - Passback Out',
              id: 'Anti - Passback Out',
            },
            {
              label: 'Anti - Passback In',
              id: 'Anti - Passback In-2',
            },
            {
              label: 'Anti - Passback In/Out',
              id: 'Anti - Passback In/Out',
            },
          ]}
        />
      </div>

      <div className="row-device">
        <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-secondary" />
        <span className="selected-devices">
          {t('selectDevice')} {levelDetails.noOfDoors}
        </span>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('save&New')}
          className="btn-primary"
        />
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default AddAntiPassback;

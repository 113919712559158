import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';

interface AddMultiPersonProps {
  name: string;
  remarks?: string;
}
interface ModalType {
  closeModal: () => void;
  data?: AddMultiPersonProps;
}
function AddMultiPersonGroup({ closeModal, data }: ModalType) {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    data || {
      name: '',
      remarks: '',
    },
  );
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: AddMultiPersonProps) => ({
      ...prev,
      [name]: values,
    }));
  };
  return (
    <div className="flex-row column" style={{ width: '38vw' }}>
      <div className="flex-row">
        <InputField
          id="name"
          name="name"
          label={t('groupName')}
          type="text"
          value={value.name}
          onChangeFunction={onChangeValue}
          required
        />
        <InputField
          id="remarks"
          name="remarks"
          label={t('remarks')}
          type="text"
          value={value.remarks}
          onChangeFunction={onChangeValue}
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={() => closeModal()}
        disabled={!value.name}
      />
    </div>
  );
}

AddMultiPersonGroup.defaultProps = {
  data: {
    remarks: '',
  },
};

export default AddMultiPersonGroup;

import { post } from './base/index';

export default {
  getWiegandFormat: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/wiegand/getListByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  addWiegandFormat: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/addWiegand`, data);
  },
  getWiegandParities: (id: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/getWiegandById?id=${id}`, {});
  },
  deleteWiegandFormat: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/deleteWiegand?ids=${id}`, {});
  },
  checkNameIsExist: (name: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/isNameExist?name=${name}`, {});
  },
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { CommunicationInterface } from '../../../interface/accessDevice.interface';

function ModifyCommunicationPassword({ closeModal }: CommunicationInterface) {
  const { t } = useTranslation();

  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const onChangeValue = (name: string, value: string) => {
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    console.log(passwords);
    closeModal();
  };

  return (
    <div className="modify-communication-modal">
      <div className="form-row">
        <div>
          <InputField
            id="newPassword"
            name="newPassword"
            label={t('newPassword')}
            type="password"
            value={passwords.newPassword}
            onChangeFunction={onChangeValue}
            aria-label="New Password"
          />
        </div>
        <div>
          <InputField
            id="confirmPassword"
            name="confirmPassword"
            label={t('confirmPassword')}
            type="password"
            value={passwords.confirmPassword}
            onChangeFunction={onChangeValue}
            errorStatus={
              passwords.confirmPassword !== '' &&
              passwords.newPassword !== passwords.confirmPassword
            }
            message="Password does not match"
            aria-label="Confirm Password"
          />
        </div>
      </div>

      <div className="modal-footer">
        <Button
          onClickFunction={closeModal}
          title={t('Cancel')}
          aria-label={t('Cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          aria-label={t('OK')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default ModifyCommunicationPassword;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import Button from '../../../shared-components/button/button';

function AddSelectedDevice({ closeModal }: any) {
  const { t } = useTranslation();
  const initialData = [
    {
      id: '0',
      deviceName: 'GDTest192.168.71.120',
      serialNumber: 'AJQE213460002',
    },
    {
      id: '1',
      deviceName: 'GDTest192.168.71.120',
      serialNumber: 'AJQE213460002',
    },
  ];
  const [timeZone, setTimeZone] = useState<any[]>(initialData); // Available timeZone
  const [selectedTimeZone, setSelectedTimeZone] = useState<any[]>([]); // Selected timeZone
  const tableColumns = [
    { label: t('deviceName'), id: 'deviceName' },
    { label: t('serialNumber'), id: 'serialNumber' },
  ];

  const changeSearch = () => {
    console.log('search');
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: any[], newSelectedData: any[]) => {
    setTimeZone(newAvailableData); // Update available data
    setSelectedTimeZone(newSelectedData); // Update selected data
  };
  return (
    <div className="access-rule-add-personnel flex-row column">
      <SwapTable
        searchValue=""
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={timeZone}
        selectedTableData={selectedTimeZone}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => console.log('close')}
          title={t('done')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default AddSelectedDevice;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
// import device from '../../../utils/api/device';
// import { useAppDispatch } from '../../../redux/store';
// import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { ReplaceModal } from '../../../interface/device.interface';
import { ClearDataIcon } from '../../../assets/svgicons/svgicon';

function ReplaceDevice({ closeModal, selectedId }: ReplaceModal) {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const [sn, setSn] = useState('');
  console.log(selectedId); // temp
  const submit = () => {
    closeModal();
  };
  return (
    <div className="replace-device">
      <div className="form">
        <div className="row-input">
          <InputField
            name="sn"
            label={t('serialNumber')}
            id="sn"
            value={sn}
            onChangeFunction={(name, value) => setSn(value)}
            type="text"
            className=""
            required
          />
        </div>
        <div className="warning-message-wrap">
          <div className="warning-msg">
            <ClearDataIcon />
            <span className="text-warning">{t('replaceWarningMessage1')}</span>
          </div>
          <div className="warning-msg">
            <ClearDataIcon />
            <span className="text-warning">{t('replaceWarningMessage2')}</span>
          </div>
        </div>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary" disabled={!sn} />
      </div>
    </div>
  );
}

export default ReplaceDevice;

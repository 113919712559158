import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import TimeInput from '../../../shared-components/time-input-field/TimeInputField';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'holiday1',
  'holiday2',
  'holiday3',
];

const intervals = ['interval1', 'interval2', 'interval3'];

type TimeStateProps = {
  hour: string;
  minute: string;
};

type DayIntervalTimes = {
  [day: string]: {
    [interval: string]: {
      startTime: TimeStateProps;
      endTime: TimeStateProps;
      doorVerification: string;
      personnelVerification: string;
    };
  };
};
type StateProps = {
  name: string;
  remarks: string;
  copySettings: string[];
  verificationMode: string;
};
interface ModalType {
  closeModal: () => void;
}
function AddVerificationMode({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const verificationOptions = [
    {
      label: t('oldVerificationMode'),
      id: '1',
      value: '1',
    },
    {
      label: t('newVerificationMode'),
      id: '2',
      value: '2',
    },
  ];
  const [timeValues, setTimeValues] = useState<DayIntervalTimes>(() => {
    const initialState: DayIntervalTimes = {};
    days.forEach((day) => {
      initialState[day] = {};
      intervals.forEach((interval) => {
        initialState[day][interval] = {
          startTime: { hour: '00', minute: '00' },
          endTime: { hour: '00', minute: '00' },
          doorVerification: '',
          personnelVerification: '',
        };
      });
    });
    return initialState;
  });
  const isMondayNotSelected = () => {
    const mondayIntervals = timeValues.monday;

    return Object.values(mondayIntervals).every((interval) => {
      return interval.doorVerification === '' && interval.personnelVerification === '';
    });
  };
  const [values, setValues] = useState<StateProps>({
    name: '',
    remarks: '',
    copySettings: [],
    verificationMode: '1',
  });
  const [modalOpen, setModalOpen] = useState(false);

  const onChangeValue = (
    day: string,
    interval: string,
    field: 'startTime' | 'endTime' | string,
    time: TimeStateProps | string | number,
  ) => {
    setTimeValues((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [interval]: {
          ...prev[day][interval],
          [field]: time,
        },
      },
    }));
  };
  const handleChange = (name: string, value: any) => {
    if (name === 'verificationMode' && value === '2') {
      setModalOpen(true);
      return;
    }
    setValues((prev: StateProps) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'copySettings') {
      const mondayValues = timeValues.monday;
      if (Object.keys(mondayValues)) {
        if (value.length > 0) {
          const updatedTimeValues = { ...timeValues };
          // Replicate Monday's values to Tuesday through Friday
          days.slice(1, 5).forEach((day) => {
            updatedTimeValues[day] = { ...mondayValues };
          });
          setTimeValues(updatedTimeValues);
        } else {
          const updatedTimeValues = { ...timeValues };
          days.slice(1, 5).forEach((day) => {
            Object.keys(updatedTimeValues[day]).forEach((interval) => {
              updatedTimeValues[day][interval] = {
                startTime: { hour: '00', minute: '00' },
                endTime: { hour: '00', minute: '00' },
                personnelVerification: '',
                doorVerification: '',
              };
            });
          });
          setTimeValues(updatedTimeValues);
        }
      }
    }
  };
  return (
    <div className="add-verification-mode">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={t('')}
        content={
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('thereIsNoDeviceThatSupportsTheNewVerificationMethod')}
            buttonLabel={t('ok')}
          />
        }
      />
      <div className="form-row-wrap">
        <div className="form-row">
          <InputField
            id="name"
            name="name"
            label={t('ruleName')}
            type="text"
            value={values.name}
            onChangeFunction={handleChange}
            required
          />
          <InputField
            id="remarks"
            name="remarks"
            label={t('remarks')}
            type="text"
            value={values.remarks}
            onChangeFunction={handleChange}
          />
        </div>
        <div className="form-row-1">
          <RadioButtonField
            label={t('verificationMode')}
            name="verificationMode"
            alignment="row-wrap"
            data={verificationOptions}
            value={values?.verificationMode}
            onChangeFunction={handleChange}
          />
          <CheckBoxInputField
            name="copySettings"
            data={[
              {
                label: t('copyMondaySettingstoOthersWeekdays'),
                id: 'yes',
                value: 'yes',
                disabled: isMondayNotSelected() ? 'disabled' : '',
              },
            ]}
            value={values?.copySettings}
            onChangeFunction={handleChange}
            alignment="column"
          />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="date-time-header" colSpan={2} rowSpan={2}>
              <span>{t('dateTime')}</span>
            </th>
            {intervals.map((interval) => (
              <th key={interval} colSpan={4}>
                {t(interval)}
              </th>
            ))}
          </tr>
          <tr>
            {intervals.map((interval) => (
              <React.Fragment key={interval}>
                <th>{t('startTime')}</th>
                <th>{t('endTime')}</th>
                <th>{t('doorVerification')}</th>
                <th>{t('personnelVerification')}</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {days.map((day) => (
            <tr key={day}>
              <td colSpan={2}>{t(day)}</td>
              {intervals.map((interval) => (
                <React.Fragment key={interval}>
                  <td>
                    <TimeInput
                      value={timeValues[day][interval].startTime}
                      onChange={(time) => onChangeValue(day, interval, 'startTime', time)}
                      hourName={`${day}-${interval}-start-hour`}
                      disabled
                      customStyle={{ width: '35px' }}
                    />
                  </td>
                  <td>
                    <TimeInput
                      minuteName={`${day}-${interval}-start-minute`}
                      value={timeValues[day][interval].endTime}
                      onChange={(time) => onChangeValue(day, interval, 'endTime', time)}
                      disabled
                      customStyle={{ width: '35px' }}
                    />
                  </td>
                  <td>
                    <div className="table-inputs">
                      <SelectBoxField
                        name="doorVerification"
                        label={t('doorVerification')}
                        id="doorVerification"
                        value={timeValues[day][interval].doorVerification}
                        onChangeFunction={(name, value) =>
                          onChangeValue(day, interval, name, value.id)
                        }
                        data={[
                          { label: t('Auto Identification'), id: 'type-one' },
                          { label: t('Only fingerprint'), id: 'type-two' },
                        ]}
                        className=""
                      />
                    </div>
                  </td>
                  <td>
                    <div className="table-inputs">
                      <SelectBoxField
                        name="personnelVerification"
                        label={t('personnelVerification')}
                        id="personnelVerification"
                        value={timeValues[day][interval].personnelVerification}
                        onChangeFunction={(name, value) =>
                          onChangeValue(day, interval, name, value.id)
                        }
                        data={[
                          { label: t('Auto Identification'), id: 'type-one' },
                          { label: t('Only fingerprint'), id: 'type-two' },
                        ]}
                        className=""
                      />
                    </div>
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t(
          'RS485 reader can only follow the door verification mode, does not support the personnel verification mode.',
        )}
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('save&New')}
          className="btn-primary"
          disabled={!values.name}
        />
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('done')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default AddVerificationMode;

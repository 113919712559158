import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

interface ModalTypeProps {
  closeModal: () => void;
}
interface AddZoneProps {
  code: string;
  name: string;
  remark?: string;
}
function AddZone({ closeModal }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addZone, setAddZone] = useState<AddZoneProps>({
    code: '',
    name: '',
    remark: '',
  });
  const [disabled, setDisabled] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setAddZone((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    try {
      console.log('submit');
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  useEffect(() => {
    if (addZone.name && addZone.code && addZone.remark) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [addZone]);
  return (
    <div className="add-zone flex-row column">
      <div className="grid-row">
        <InputField
          id="code"
          name="code"
          label={t('zoneCode')}
          type="text"
          value={addZone.code}
          onChangeFunction={onChangeValue}
          required
        />
        <InputField
          id="name"
          name="name"
          label={t('name')}
          type="text"
          value={addZone.name}
          onChangeFunction={onChangeValue}
          required
        />
        <InputField
          id="remark"
          name="remark"
          label={t('remark')}
          type="text"
          value={addZone.remark}
          onChangeFunction={onChangeValue}
          required
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

// AddZone.defaultProps = {
//   remark: '',
// };

export default AddZone;

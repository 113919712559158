import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddPersonSelectedDevice from '../../anti-passback/components/Add-AntiSelectedDevice';
import ModalBox from '../../../shared-components/modal-box/modal-box';

interface AddFirstPersonProps {
  closeModal: () => void;
}
interface FirstPersonDevice {
  id: string;
  doorName: string;
  ownedDevice: string;
  serialNumber: string;
}
function AddFirstPerson({ closeModal }: AddFirstPersonProps) {
  const { t } = useTranslation();
  const tableData: FirstPersonDevice[] = [
    {
      id: '0',
      doorName: '192.168.20.132',
      ownedDevice: '192.168.20.132',
      serialNumber: 'CGFE191060044',
    },
    {
      id: '1',
      doorName: '192.168.20.34',
      ownedDevice: '192.168.20.34',
      serialNumber: 'COPP230760001',
    },
    {
      id: '2',
      doorName: 'testphil',
      ownedDevice: 'testphil',
      serialNumber: 'AJQE213460002',
    },
  ];
  const [devices, setDevices] = useState<FirstPersonDevice[]>(tableData);
  const [selectedDevices, setSelectedDevices] = useState<FirstPersonDevice[]>([]);
  const [levelDetails, setLevelDetails] = useState({
    doorName: '',
    passageModeTimeZone: '',
    noOfDoors: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addPersonSelectedDevice'),
    content: <>hi</>,
  });
  const onChangeValue = (doorName: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [doorName]: value,
    }));
  };
  const onTableSwap = (
    newAvailableData: FirstPersonDevice[],
    newSelectedData: FirstPersonDevice[],
  ) => {
    setDevices(newAvailableData); // Update available data
    setSelectedDevices(newSelectedData); // Update selected data
  };
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'ownedDevice' },
    { label: t('serialNumber'), id: 'serialNumber' },
  ];
  const changeSearch = () => {
    console.log('search');
  };
  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('selectDoor'),
      content: (
        <AddPersonSelectedDevice
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          onTableSwap={onTableSwap}
        />
      ),
    });
  };

  // const submit = () => {
  //   // Handle submit logic
  //   console.log(levelDetails);
  //   closeModal();
  // };

  return (
    <div className="add-level-modal" style={{ width: '676px' }}>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="doorName"
          name="doorName"
          label={t('doorName')}
          type="text"
          value={levelDetails.doorName}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          id="passageModeTimeZone"
          name="passageModeTimeZone"
          label={t('passageModeTimeZone')}
          value={levelDetails.passageModeTimeZone}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            {
              label: 'Anti - Passback Out',
              id: 'Anti - Passback Out',
            },
            {
              label: 'Anti - Passback In',
              id: 'Anti - Passback In-2',
            },
            {
              label: 'Anti - Passback In/Out',
              id: 'Anti - Passback In/Out',
            },
          ]}
        />
      </div>

      <div className="row-device">
        <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-secondary" />
        <span className="selected-devices">
          {t('selectDevice')} {levelDetails.noOfDoors}
        </span>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('save&New')}
          className="btn-primary"
        />
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default AddFirstPerson;

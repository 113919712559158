import { post } from '../base';

export default {
  saveMap: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/map/save`, data, {}, true);
  },
  isExistName: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}api/map/isExist/${data}`, {}),
  // getDoorList: (data: any = {}) => {
  //   return post(
  //     `${process.env.REACT_APP_API_URL}api/accLevelByPerson/selectLevelPersonList`,
  //     data,
  //     {
  //       pageNo: 1,
  //       pageSize: 50,
  //     },
  //   );
  // },
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { AddDayLightProps } from '../../../interface/accessDevice.interface';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import dayLightSavingTimeApi from '../../../utils/api/Devices/daylightSavingTime';
import {
  fetchDaylightSavingTime,
  fetchTimeZone,
} from '../../../redux/slices/DayLightSavingTimeSlice';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';

function AddDayLight({ initialData, closeModal, data, saveChanges = () => {} }: AddDayLightProps) {
  const { t } = useTranslation();
  const { timeZone } = useAppSelector((state: any) => state.daylightSavingTime);
  const dispatch = useAppDispatch();
  const [deviceConfig, setDeviceConfig] = useState(initialData);

  const onChangeValue = (name: string, value: any) => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAcceptStartTime = (formattedTime: string) => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      startTime: formattedTime,
    }));
  };

  const handleAcceptEndTime = (formattedTime: string) => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      endTime: formattedTime,
    }));
  };

  const submit = () => {
    const request = {
      name: deviceConfig?.name,
      startTime: deviceConfig?.startTime,
      endTime: deviceConfig?.endTime,
      timeZone: deviceConfig?.timeZone,
      dstimeMode: 1,
      bussinessId: deviceConfig?.bussinessId,
    };
    dayLightSavingTimeApi
      ?.saveDaylight(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = data
            ? t('DayLightSavingUpdatedSuccessfully')
            : t('DayLightSavingAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchDaylightSavingTime({ pageNo: 1 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    dispatch(fetchTimeZone('systemTimeZone'));
  }, []);

  return (
    <div className="add-daylight">
      <div className="form-row">
        <InputField
          id="name"
          name="name"
          label={t('dstName')}
          type="text"
          value={deviceConfig?.name}
          onChangeFunction={onChangeValue}
          aria-label="DST Name"
        />
        <SelectBoxField
          id="timeZone"
          name="timeZone"
          label={t('timeZone')}
          value={deviceConfig?.timeZone}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={timeZone}
        />
      </div>

      <div className="form-start">
        <InputDateTime
          label={t('startTime')}
          value={deviceConfig?.startTime}
          onChangeFunction={() => {}}
          okFunction={handleAcceptStartTime}
        />
        <InputDateTime
          label={t('endTime')}
          value={deviceConfig?.endTime}
          onChangeFunction={() => {}}
          okFunction={handleAcceptEndTime}
        />
      </div>

      <div className="modal-footer">
        <Button
          onClickFunction={submit}
          title={t('save&New')}
          aria-label="Save and create new entry"
          className="btn-primary"
        />
        <Button
          onClickFunction={() => closeModal()}
          title={t('cancel')}
          aria-label="Cancel"
          className="btn-default"
        />
        <Button
          onClickFunction={() => {
            saveChanges(deviceConfig);
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default AddDayLight;

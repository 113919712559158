import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import { AddIcon } from '../../assets/svgicons/svgicon';
import AddMultiCombination from './components/Add-MultiCombination';

interface ActionButton {
  label: string;
  function: (val: any) => void;
}

function MultiPersonOpeningDoor() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<string[]>([]);
  console.log(selectedFullData); // use after api integration
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'combinationName',
      header: t('combinationName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.combinationName}
        </div>
      ),
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
    },
    {
      accessorKey: 'doorNumber',
      header: t('doorNumber'),
    },
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'currentPersonnelCount',
      header: t('currentPersonnelCount'),
    },
    {
      accessorKey: 'multiPersonGroup',
      header: t('multiPersonGroup'),
    },
  ];
  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };
  handleEditOpen = () => {
    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: <AddMultiCombination closeModal={() => setModalOpen(false)} />,
    });
  };
  const actionButtons: ActionButton[] = [
    {
      label: t('edit'),
      function: (val) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={() => {
                console.log('Delete confirmed for id:', val.id);
                setModalOpen(false);
              }}
            />
          ),
        });
      },
    },
  ];
  const tempData = [
    {
      combinationName: 'Pro Access',
      deviceName: 'Pro Access',
      doorNumber: 'Pro Access',
      doorName: '192. 168. 50. 220',
      currentPersonnelCount: '192. 168. 50. 220',
      multiPersonGroup: '192. 168. 50. 220',
    },
    {
      combinationName: 'Pro Access',
      deviceName: 'Pro Access',
      doorNumber: 'Pro Access',
      doorName: '192. 168. 50. 220',
      currentPersonnelCount: '192. 168. 50. 220',
      multiPersonGroup: '192. 168. 50. 220',
    },
    {
      combinationName: 'Pro Access',
      deviceName: 'Pro Access',
      doorNumber: 'Pro Access',
      doorName: '192. 168. 50. 220',
      currentPersonnelCount: '192. 168. 50. 220',
      multiPersonGroup: '192. 168. 50. 220',
    },
  ];
  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('multiPersonOpeningDoor'),
          },
        ]}
      />
      <CardBox
        title={t('listOfCombination')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddMultiCombination closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        {' '}
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedFullData(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          // isFetching={status === 'loading'} // Placeholder status
          values={tempData} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default MultiPersonOpeningDoor;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';

function LatestEventsPopup() {
  const { t } = useTranslation();
  return (
    <div className="latest-events-popup">
      <div className="table-wrapper">
        <Table
          header={[
            { label: t('eventId'), id: 'eventId' },
            { label: t('time'), id: 'time' },
            { label: t('areaName'), id: 'areaName' },
            { label: t('deviceName'), id: 'deviceName' },
            { label: t('eventPoint'), id: 'eventPoint' },
            { label: t('eventDescription'), id: 'eventDescription' },
            { label: t('eventLevel'), id: 'eventLevel' },
            { label: t('media'), id: 'media' },
            { label: t('personId'), id: 'personId' },
            { label: t('firstName'), id: 'firstName' },
            { label: t('lastName'), id: 'lastName' },
            { label: t('cardNumber'), id: 'cardNumber' },
            { label: t('departmentNumber'), id: 'departmentNumber' },
            { label: t('departmentName'), id: 'departmentName' },
            { label: t('readerName'), id: 'readerName' },
          ]}
          value={[]} // Display the current issued cards
        />
      </div>
    </div>
  );
}

export default LatestEventsPopup;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
// import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import { LoadingProgressCount, sortValuesByStatus } from '../../../utils/helpers';
import deviceApi from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

function SynchronizeAllDataToDevice({ closeModal, selectedValues, selectedId }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [responseMsg, setResponseMsg] = useState('');
  const [values, setValue] = useState({
    devices: [],
  });
  const [selectText, setSelectText] = useState(t('selectAll'));
  const [syncAllData, setSyncAllData] = useState([]);
  const onChangeValue = (name: string, changeValue: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };
  const submit = () => {
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    const request = selectedId.join('&');
    deviceApi
      .synchronizeTime(request)
      .then((response) => {
        setResponseMsg(response.data.success);
        if (response.data.success) {
          setTimeout(() => {
            stopProgress();
            setLoading('0');
          }, 3500);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const clickSelectAllDevice = () => {
    if (values?.devices?.length === syncAllData.length) {
      // All devices are selected, so unselect all
      setValue((prevValue: any) => ({
        ...prevValue,
        devices: [], // Clear the `devices` array
      }));
      setSelectText(t('selectAll'));
    } else {
      const allDeviceValues = syncAllData?.map((device: any) => device.value);
      setValue((prevValue: any) => ({
        ...prevValue, // Keep other values like `standAlone` unchanged
        devices: allDeviceValues, // Select all devices
      }));
      setSelectText(t('unselectAll'));
    }
  };
  const getSyncData = () => {
    const req = {
      devIds: selectedId.join('&'),
      devType: 'CONTROL',
    };
    deviceApi
      .syncAllDataByDevType(req)
      .then((res) => {
        setValue((prev) => ({
          ...prev,
          devices: res.data.data,
        }));
        setSelectText(t('unselectAll'));
        const syncData = res.data.data.map((item: string, i: number) => ({
          label: item.charAt(0).toUpperCase() + item.slice(1),
          value: item,
          id: i,
        }));
        setSyncAllData(syncData);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    getSyncData();
  }, []);
  /**
   * @sortValuesByStatus - Helper to sort devices based on their online/offline/disabled status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const getOnlineDevice = selectedDevices.map((data: any) => data?.status).includes('online');
  return (
    <div className="device-popup" style={{ width: '47vw' }}>
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <div className="select-device-wrapper">
                    <RadioButtonField
                      label=""
                      name="status"
                      alignment="column"
                      data={[
                        {
                          label: 'Standalone device: ',
                          id: 'on',
                          value: 'on',
                        },
                      ]}
                      value="on"
                      onChangeFunction={() => {}}
                    />
                    <span className="active-name">{device.deviceName}</span>
                  </div>
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="column-1">
          <div className="checkbox-wrapper">
            <div>
              <Button
                onClickFunction={clickSelectAllDevice}
                title={selectText}
                className="btn-primary"
              />
            </div>
            <CheckBoxInputField
              label=""
              name="devices"
              alignment="warp grid-2"
              required
              data={syncAllData}
              value={values.devices}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div>
                <div className="form-buttons-right">
                  <Button
                    onClickFunction={submit}
                    title={t('synchronize')}
                    className={`btn-primary ${loading === '1' ? 'loading' : ''} `}
                    disabled={!getOnlineDevice}
                  />
                  <Button
                    onClickFunction={() => {
                      closeModal();
                    }}
                    title={t('close')}
                    className="btn-default"
                  />
                </div>
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && responseMsg && (
                <div className="success-wrapper">
                  <p>Data Processing, Please Wait.....</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.deviceName}: </span>
                    ))}
                    Sending commands completed...
                  </p>
                  <p>Synchronize Time...</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.deviceName}: </span>
                    ))}
                    Time Synchronization successful...
                  </p>
                  <p>Completed</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SynchronizeAllDataToDevice;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import accessByDepartment from '../../utils/api/AccessRule/accessByDepartment';
import accessByLevel from '../../utils/api/AccessRule/accessByLevel';

// Define the type for a department object
interface AccessByLevels {
  id: string;
  name: string;
  areaName: string;
  timeZone: string;
}

// Thunk for fetching the level list
export const fetchAccessByLevels = createAsyncThunk(
  'cards/fetchAccessByLevels',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByLevel.getListPage(data);
      console.log(response.data.data.data);
      return {
        ...response?.data?.data.data,
        data: {
          list: response?.data?.data?.data,
          size: response?.data?.data?.data?.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
export const fetchListLevel = createAsyncThunk(
  'accessByLevel/fetchListLevel',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByLevel.getListLevel(data);
      console.log('Response Data:', response.data);
      return {
        ...response.data,
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
// Thunk for fetching the department list
export const fetchDeptLevel = createAsyncThunk(
  'cards/fetchDeptLevel',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByDepartment.getDeptLevel(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
// Thunk for fetching the department list
export const fetchAccessDeptLevel = createAsyncThunk(
  'cards/fetchAccessDeptLevel',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByLevel.getLevelPerson(data);
      console.log(response);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
// Initial state for the slice
interface LevelState {
  levels: AccessByLevels[]; // List of issued cards
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
  levelList: AccessByLevels[]; // List of access by list
  levelStatus: string;
  levelTotalDataCount: number;
  levelCurrentPageNo: number;
  levelSearchFilter: any;
}

const initialState: LevelState = {
  levels: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  levelList: [],
  levelStatus: 'success',
  levelTotalDataCount: 0,
  levelCurrentPageNo: 0,
  levelSearchFilter: {},
};

// Create the slice
export const accessByLevelSlice = createSlice({
  name: 'accessByLevel',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
    deleteLevels: (state, action: PayloadAction<any>) => {
      console.log(action.payload);
      state.levels = state.levels.filter((val: any) => val.code !== action.payload);
      state.levelTotalDataCount -= 1;
    },
    setLevelSearchFilter: (state, action: PayloadAction<any>) => {
      state.levelSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchDeptLevel actions
    builder
      .addCase(fetchDeptLevel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeptLevel.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.currentPageNo === 1) {
            state.levels = action.payload.data.list;
          } else {
            state.levels = [...state.levels, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchDeptLevel.rejected, (state) => {
        state.status = 'failed';
      });

    // access by level -> level list redux
    builder
      .addCase(fetchAccessByLevels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccessByLevels.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload.code);
        if (action.payload) {
          state.levelTotalDataCount = action.payload.data.size;
          state.levelCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.levelCurrentPageNo === 1) {
            state.levelList = action.payload.data.list;
          } else {
            console.log(action.payload.data.list);
            state.levelList = [...state.levelList, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchAccessByLevels.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchAccessDeptLevel.pending, (state) => {
        state.levelStatus = 'loading';
      })
      .addCase(fetchAccessDeptLevel.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.levelTotalDataCount = action.payload.data.size;
          state.levelCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.levelCurrentPageNo === 1) {
            state.levels = action.payload.data.list;
          } else {
            state.levels = [...state.levels, ...action.payload.data.list];
          }
        }
        state.levelStatus = 'success';
      })
      .addCase(fetchAccessDeptLevel.rejected, (state) => {
        state.levelStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setLevelSearchFilter, deleteLevels } = accessByLevelSlice.actions;

export default accessByLevelSlice.reducer;

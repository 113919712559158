import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/auxOut/List`, {}, { ...data, pageSize: 10 });
  },
  getTimeSegList: () => {
    return get(`${process.env.REACT_APP_API_URL}api/timeSeg/getTimeSegList`, {});
  },
  saveAuxOut: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/auxOut/Edit`, data, {});
  },
  getAuxOutIds: (data: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}api/auxOut/getAuxOutIds?ids=${data.ids}&type=${data.type}`,
      {},
    ),
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { CommunicationInterface } from '../../../interface/accessDevice.interface';

function SwitchNetworkConnection({ closeModal }: CommunicationInterface) {
  const { t } = useTranslation();

  const [selectedDevice, setSelectedDevice] = useState('Wired');

  const onChangeValue = (name: string, value: string | number) => {
    setSelectedDevice(value.toString()); // Ensures value is always a string
  };

  const submit = () => {
    console.log(`Selected Device: ${selectedDevice}`);
    closeModal();
  };

  return (
    <div className="switch-network-modal">
      <div className="form-row">
        <label htmlFor="networkType">{t('selectedDevice')}</label>
        <RadioButtonField
          label=""
          name="networkType"
          alignment=""
          data={[
            { label: t('wired'), id: 'wired', value: 'Wired' },
            { label: t('4G'), id: '4g', value: '4G' },
            { label: t('wIFI'), id: 'wifi', value: 'WIFI' },
          ]}
          value={selectedDevice}
          onChangeFunction={onChangeValue}
        />
      </div>

      <div className="modal-footer">
        <Button
          onClickFunction={closeModal}
          title={t('Cancel')}
          aria-label={t('Cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          aria-label={t('OK')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default SwitchNetworkConnection;

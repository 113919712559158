import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import AddMultiSelected from './Add-MultiSelected';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Label from '../../../shared-components/label/Label';

interface MultiCombinationProps {
  closeModal: () => void;
}
interface Device {
  id: string;
  serialNumber: string;
  doorName?: string;
  ownedDevice: string;
}
function AddMultiCombination({ closeModal }: MultiCombinationProps) {
  const { t } = useTranslation();
  const tableData: Device[] = [
    {
      id: '0',
      serialNumber: 'CJPM230660002',
      doorName: 'Test - 1',
      ownedDevice: 'Test - 1',
    },
    {
      id: '1',
      serialNumber: 'CJPM230660002',
      doorName: 'Test - 1',
      ownedDevice: 'Test - 1',
    },
  ];
  const [devices, setDevices] = useState<Device[]>(tableData);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [levelDetails, setLevelDetails] = useState({
    combinationName: '',
    noOfDoors: 0,
  });

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [groupValues, setGroupValues] = useState(
    Array(5)
      .fill(null)
      .map((_, index) => ({
        id: `group-${index + 1}`,
        firstSelect: '',
        secondSelect: '',
      })),
  );

  const optionsData = [
    { label: '1', id: '1' },
    { label: '2', id: '2' },
    { label: '3', id: '3' },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesspersonopening'),
    content: <>hi</>,
  });
  const changeSearch = () => {
    console.log('search');
  };
  const onTableSwap = (newAvailableData: Device[], newSelectedData: Device[]) => {
    setDevices(newAvailableData); // Update available data
    setSelectedDevices(newSelectedData); // Update selected data
  };
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'ownedDevice' },
    { label: t('serialNumber'), id: 'serialNumber' },
  ];
  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('selectDoor'),
      content: (
        <AddMultiSelected
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          onTableSwap={onTableSwap}
          getSelected={(selected: Device[]) => setSelectedDevices(selected)}
        />
      ),
    });
  };
  const handleFirstSelectChange = (groupId: string, valueId: string) => {
    setGroupValues((prev) =>
      prev.map((group) =>
        group.id === groupId
          ? { ...group, firstSelect: valueId, secondSelect: '' } // Reset secondSelect when firstSelect changes
          : group,
      ),
    );
  };

  const handleSecondSelectChange = (groupId: string, valueId: string) => {
    setGroupValues((prev) =>
      prev.map((group) => (group.id === groupId ? { ...group, secondSelect: valueId } : group)),
    );
  };

  return (
    <div className="add-level-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="forms-row">
        <InputField
          id="combinationName"
          name="combinationName"
          label={t('combinationName')}
          type="text"
          value={levelDetails.combinationName}
          onChangeFunction={(name, value) => onChangeValue(name, value)}
          required
        />
      </div>

      <div className="row-device">
        <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-secondary" />
        <span className="selected-devices">
          {t('selectDevice')} {levelDetails.noOfDoors}
        </span>
      </div>
      <div className="group-select-boxes">
        {groupValues.map((group) => (
          <div key={group.id} className="group">
            <Label title={`Group ${group.id.split('-')[1]}`} />
            <SelectBoxField
              id={`firstSelect${group.id}`}
              name={`firstSelect${group.id}`}
              label=""
              value={group.firstSelect}
              onChangeFunction={(name, value) =>
                handleFirstSelectChange(group.id, String(value.id))
              }
              data={[
                {
                  label: 'Anti - Passback Out',
                  id: 'Anti - Passback Out',
                },
                {
                  label: 'Anti - Passback In',
                  id: 'Anti - Passback In-2',
                },
                {
                  label: 'Anti - Passback In/Out',
                  id: 'Anti - Passback In/Out',
                },
              ]}
            />
            <SelectBoxField
              id={`secondSelect${group.id}`}
              name={`secondSelect${group.id}`}
              value={group.secondSelect}
              onChangeFunction={(name, value) =>
                handleSecondSelectChange(group.id, String(value.id))
              }
              data={optionsData}
              disabled={!group.firstSelect} // Enable secondSelect only if firstSelect has a value
              readOnly
            />
          </div>
        ))}
      </div>

      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={() => closeModal()}
        disabled={!levelDetails.combinationName}
      />
    </div>
  );
}

export default AddMultiCombination;

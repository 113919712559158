import { post } from './base/index';

export default {
  login: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}Auth/auth/authenticate`, { ...params });
  },
  getAuthArea: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Auth/auth/authArea/getAreaList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ListDropdown from '../../shared-components/list-dropdown/list-dropdown';

import { controlOptions, exportOptions, importOptions } from './components/levels-options';
// Use assets svg icons
import ConformationBox from '../../shared-components/conformation-box/conformation-box';

import { AddIcon } from '../../assets/svgicons/svgicon';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import RemoteOpeningModal from './components/remote-opening';
import RemoteClosingModal from './components/remote-closing';
import CancelAlarmModal from './components/cancel-alarm';
import RemoteNormallyOpen from './components/remote-normally-open';
import ActivateLockdown from './components/activate-lockdown';
import DeActivateLockdown from './components/deactivate-lockdown';
import EnableIntradayPassageMode from './components/enable-Intraday-passage-mode';
import DisableIntradayPassageMode from './components/disable-Intraday-passage-mode';

// Import card slice actions
import { fetchDeviceList, setSearchFilter } from '../../redux/slices/DeviceSlice';

// Import AddLevel component (assuming it's defined elsewhere)
import ExportAccessLevel from './components/export-access-level';
import ExportDoorAccessLevel from './components/export-door-access-levels';
import ImportAccessLevel from './components/import-access-level';
import ImportDoorsAccessLevel from './components/import-doors-access-levels';
import AddLevelModal from './components/add-levels';

function AccessLevels() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { devices, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.device,
  );
  const [selectedData, setSelectedData] = useState<string[]>([]); // Add state to hold selected cards
  console.log(selectedData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesslevels'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Add state to hold selected cards
  let handleEditOpen: any;

  const accessLevelColumnsField = [
    {
      accessorKey: 'levelName',
      header: t('levelName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.levelName}
        </div>
      ),
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeZone',
      header: t('timeZone'),
    },
    {
      accessorKey: 'doorCount',
      header: t('doorCount'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'ownedDevice',
      header: t('ownedDevice'),
    },
  ];

  // Sample data for the access levels table (replace with actual data)
  const levelslist = [
    {
      levelName: 'Free Rotation',
      areaName: 'asd',
      timeZone: '2 to 10',
      doorCount: '0',
    },
    {
      levelName: 'My Testing',
      areaName: 'ASC',
      timeZone: 'Full Day Access',
      doorCount: '1',
    },
  ];

  // Sample data for the doors table (replace with actual data)
  const doorsList = [{ doorName: 'Free Rotation', ownedDevice: 'asd' }];

  const fetchData = async (params: any) => {
    try {
      if (status === 'success') {
        dispatch(fetchDeviceList({ ...params }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };
  const handleClickControl = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'remoteOpening') {
        setModalOpen(true);
        setModalView({
          title: t('remoteOpening'),
          content: <RemoteOpeningModal closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'remoteClosing') {
        setModalOpen(true);
        setModalView({
          title: t('remoteClosing'),
          content: <RemoteClosingModal closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'cancelAlarm') {
        setModalOpen(true);
        setModalView({
          title: t('cancelAlarm'),
          content: <CancelAlarmModal closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'remoteNormallyOpen') {
        setModalOpen(true);
        setModalView({
          title: t('remoteNormallyOpen'),
          content: <RemoteNormallyOpen closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'activateLockdown') {
        setModalOpen(true);
        setModalView({
          title: t('activateLockdown'),
          content: <ActivateLockdown closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'deActivateLockdown') {
        setModalOpen(true);
        setModalView({
          title: t('deActivateLockdown'),
          content: <DeActivateLockdown closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'enableIntradayPassageMode') {
        setModalOpen(true);
        setModalView({
          title: t('enableIntradayPassageMode'),
          content: <EnableIntradayPassageMode closeModal={() => setModalOpen(false)} />,
        });
      } else if (value === 'disableIntradayPassageMode') {
        setModalOpen(true);
        setModalView({
          title: t('disableIntradayPassageMode'),
          content: <DisableIntradayPassageMode closeModal={() => setModalOpen(false)} />,
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeControl = (value: any) => {
    handleClickControl(value);
  };
  const exportData = (data: any) => {
    console.log(data);
  };
  const importData = (data: any) => {
    console.log(data);
  };
  const handleClickExport = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'exportAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('exportAccessLevel'),
          content: (
            <ExportAccessLevel closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      } else if (value === 'exportDoorsOfAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('exportDoorsOfAccessLevel'),
          content: (
            <ExportDoorAccessLevel
              closeModal={() => setModalOpen(false)}
              submitFunction={exportData}
            />
          ),
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };
  const handleClickImport = (value: string = '') => {
    if (selectedCards.length > 0) {
      if (value === 'importAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('importAccessLevel'),
          content: (
            <ImportAccessLevel closeModal={() => setModalOpen(false)} submitFunction={importData} />
          ),
        });
      } else if (value === 'importDoorsOfAccessLevel') {
        setModalOpen(true);
        setModalView({
          title: t('importDoorsOfAccessLevel'),
          content: (
            <ImportDoorsAccessLevel
              closeModal={() => setModalOpen(false)}
              submitFunction={exportData}
            />
          ),
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeExport = (value: any) => {
    handleClickExport(value);
  };
  const changeImport = (value: any) => {
    handleClickImport(value);
  };
  handleEditOpen = () => {
    setModalOpen(true);
    setModalView({
      title: t('editAttribute'),
      content: <AddLevelModal closeModal={() => setModalOpen(false)} />,
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={() => {
                console.log('Delete confirmed for id:', val.id);
                setModalOpen(false);
              }}
            />
          ),
        });
      },
    },
  ];

  const moreActionButton = [
    {
      dropdown: (
        <ListDropdown
          data={controlOptions}
          label={t('doorControl')}
          changeFunction={changeControl}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickControl,
    },
    {
      dropdown: (
        <ListDropdown
          data={exportOptions}
          label={t('export')}
          changeFunction={changeExport}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickExport,
    },
    {
      dropdown: (
        <ListDropdown
          data={importOptions}
          label={t('Import')}
          changeFunction={changeImport}
          disabled={selectedCards.length > 0}
        />
      ),
      clickFunction: handleClickImport,
    },
  ];
  useEffect(() => {
    if (devices.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('accessLevels'),
          },
        ]}
      />
      <CardBox
        title={t('listOfAccessLevels')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddLevelModal closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={accessLevelColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          values={levelslist}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
        <h4>{t('Browse Levels Janitors (ZKTeco) Door Combination')}</h4>
        <div className="doorlist">
          <p>List Of Doors</p>
        </div>
        <DataTable
          action={actionButtons}
          columns={doorColumnsField}
          values={doorsList}
          actionButtonStatus={!modalOpen}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AccessLevels;

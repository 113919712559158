/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import holidays from '../../utils/api/AccessRule/holidays';
import { setNotificationError } from './NotificationSlices';

export const fetchHolidays = createAsyncThunk(
  'auxiliaryOutput/fetchHolidays',
  async (data: any, { dispatch }) => {
    const apiResponse = await holidays
      .listByPage(data)
      .then((response) => {
        console.log(response);
        return {
          ...response.data,
          data: {
            list: response.data.data.map((val: any) => ({
              ...val,
              id: val?.id,
            })),
            size: response.data.data.size,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return apiResponse;
  },
);

const initialState = {
  holidays: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const holidaysSlice: any = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidays.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchHolidays.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.holidays = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo += 1;
        }
        state.status = 'success';
      })
      .addCase(fetchHolidays.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = holidaysSlice.actions;

export default holidaysSlice.reducer;

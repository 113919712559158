import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import holidaysApi from '../../../utils/api/AccessRule/holidays';
import { fetchHolidays } from '../../../redux/slices/HolidaysSlices';

type AddData = {
  name: string;
  remarks: string;
  startDate?: string | Dayjs | null;
  endDate?: string | Dayjs | null;
  recurring: string;
  holidayType?: string;
  isLoopByYear?: string;
};

interface AddHolidaysInterface {
  closeModal: () => void;
  data?: AddData;
}

function AddHolidays({ closeModal, data }: AddHolidaysInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = [
    {
      label: t('yes'),
      id: 'yes',
      value: 'yes',
    },
    {
      label: t('no'),
      id: 'no',
      value: 'no',
    },
  ];

  const [addHolidays, setAddHolidays] = useState<AddData>({
    name: data?.name || '',
    holidayType: data?.holidayType || '',
    remarks: data?.remarks || '',
    startDate: data?.startDate ? dayjs(data.startDate) : dayjs(),
    endDate: data?.endDate ? dayjs(data.endDate) : dayjs(),
    recurring: data?.recurring || 'yes',
    isLoopByYear: data?.isLoopByYear || 'no',
  });
  const [disable, setDisable] = useState(false);

  const onChangeValue = (name: string, value: any) => {
    setAddHolidays((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeDateTime = (dateValue: any, type: string) => {
    setAddHolidays((prev: any) => ({
      ...prev,
      [type]: dayjs(dateValue),
    }));
  };

  const handleAcceptDate = (dateValue: any, type: string) => {
    setAddHolidays((prev: any) => ({
      ...prev,
      [type]: dayjs(dateValue),
    }));
  };

  const submit = () => {
    const request = {
      name: addHolidays?.name,
      holidayType: addHolidays?.holidayType,
      remarks: addHolidays?.remarks,
      startDate: addHolidays?.startDate,
      endDate: addHolidays?.endDate,
      recurring: addHolidays?.recurring,
      isLoopByYear: addHolidays?.isLoopByYear === 'yes',
    };
    holidaysApi
      .saveHolidays(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = data ? t('holidayUpdatedSuccessfully') : t('holidayAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchHolidays({ pageNo: 1 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (
      addHolidays?.name &&
      addHolidays?.holidayType &&
      addHolidays?.startDate &&
      addHolidays?.endDate &&
      addHolidays?.recurring
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [addHolidays]);

  return (
    <div className="add-holidays">
      <div className="grid-row">
        <div className="col">
          <InputField
            name="name"
            label={t('name')}
            id="name"
            type="text"
            value={addHolidays?.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="holidayType"
            label={t('holidayType')}
            id="holidayType"
            value={addHolidays?.holidayType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('holidayType 1'), id: '1' },
              { label: t('holidayType 2'), id: '2' },
              { label: t('holidayType 3'), id: '3' },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <InputField
            name="remarks"
            label={t('remarks')}
            id="remarks"
            type="text"
            value={addHolidays?.remarks}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <InputDateTime
            label={t('startDate')}
            value={addHolidays?.startDate}
            onChangeFunction={(val) => changeDateTime(val, 'startDate')}
            okFunction={(val) => handleAcceptDate(val, 'startDate')}
          />
        </div>
        <div className="col">
          <InputDateTime
            label={t('endDate')}
            value={addHolidays?.endDate}
            onChangeFunction={(val) => changeDateTime(val, 'endDate')}
            okFunction={(val) => handleAcceptDate(val, 'endDate')}
          />
        </div>
      </div>
      <div className="col">
        <RadioButtonField
          label={t('recurring')}
          name="recurring"
          alignment="row-wrap"
          data={options}
          value={addHolidays?.recurring ?? ''}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddHolidays.defaultProps = {
  data: {
    name: '',
    deviceName: '',
    remarks: '',
    eventNumber: '',
    startDate: '',
    endDate: '',
    recurring: 'yes',
    isLoopByYear: 'no',
  },
};

export default AddHolidays;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import Table from '../../../shared-components/table/table';

interface ModalType {
  closeModal: () => void;
}
function AddDoor({ closeModal }: ModalType) {
  const { t } = useTranslation();

  const initialData = [
    {
      id: '0',
      doorName: '192.168.20.112',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
    { id: '1', doorName: 'door 2', ownedDevice: '192.168.20.112', serialNumber: '6620223240005' },
    { id: '2', doorName: 'door 3', ownedDevice: '192.168.20.112', serialNumber: '6620223240005' },
  ];

  const [doors, setDoors] = useState<any[]>(initialData); // Available doors
  const [selectedDoors, setSelectedDoors] = useState<any[]>([]); // Selected doors
  const [selectedDoorIds, setSelectedDoorIds] = useState<string[]>([]); // Track selected door IDs for "doors" table
  const [selectedSelectedDoorIds, setSelectedSelectedDoorIds] = useState<string[]>([]); // Track selected door IDs for "selectedDoors" table

  // Move selected doors from available to selected
  const handleAddSelectedDoors = () => {
    const newSelectedDoors = doors.filter((door) => selectedDoorIds.includes(door.id));
    setSelectedDoors([...selectedDoors, ...newSelectedDoors]);
    setDoors(doors.filter((door) => !selectedDoorIds.includes(door.id)));
    setSelectedDoorIds([]); // Clear selected available doors
  };

  // Move selected doors from selected back to available
  const handleRemoveSelectedDoors = () => {
    const newAvailableDoors = selectedDoors.filter((door) =>
      selectedSelectedDoorIds.includes(door.id),
    );
    setDoors([...doors, ...newAvailableDoors]);
    setSelectedDoors(selectedDoors.filter((door) => !selectedSelectedDoorIds.includes(door.id)));
    setSelectedSelectedDoorIds([]); // Clear selected doors in the selected list
  };

  // Handle selection in available doors table
  const handleSelectAvailableDoors = (selectedRows: string[]) => {
    setSelectedDoorIds(selectedRows);
  };

  // Handle selection in selected doors table
  const handleSelectSelectedDoors = (selectedRows: string[]) => {
    setSelectedSelectedDoorIds(selectedRows);
  };

  return (
    <div className="level-add-door">
      <div className="table-container">
        <div className="table-wrapper">
          <h4 className="title">{t('alternative')}</h4>
          <Table
            header={[
              { label: t('doorName'), id: 'doorName' },
              { label: t('ownedDevice'), id: 'ownedDevice' },
              { label: t('serialNumber'), id: 'serialNumber' },
            ]}
            value={doors}
            checkBoxStatus
            selectedValues={selectedDoorIds} // Track selected rows in "doors" table
            onSelect={handleSelectAvailableDoors} // Handle selection in "doors" table
            multiSelect // Assuming this prop enables multi-selection
          />
        </div>
        <div className="buttons">
          <Button onClickFunction={handleAddSelectedDoors} title=">" className="btn-default" />
          <Button onClickFunction={handleRemoveSelectedDoors} title="<" className="btn-default" />
        </div>
        <div className="table-wrapper">
          <h4 className="title">{t('selected')}</h4>
          <Table
            header={[
              { label: t('doorName'), id: 'doorName' },
              { label: t('ownedDevice'), id: 'ownedDevice' },
              { label: t('serialNumber'), id: 'serialNumber' },
            ]}
            value={selectedDoors}
            checkBoxStatus
            selectedValues={selectedSelectedDoorIds} // Track selected rows in "selectedDoors" table
            onSelect={handleSelectSelectedDoors} // Handle selection in "selectedDoors" table
            multiSelect // Assuming this prop enables multi-selection
          />
        </div>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => console.log('submit')}
          title={t('done')}
          className="btn-primary"
          // Removed the disabled condition
        />
      </div>
    </div>
  );
}

export default AddDoor;

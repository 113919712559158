import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddDoorModal from './add-doors';
import ModalBox from '../../../shared-components/modal-box/modal-box';

type ModalType = {
  closeModal: () => void;
};
function AddLevelModal({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const [levelDetails, setLevelDetails] = useState({
    levelName: '',
    timeZone: '',
    area: '',
    noOfDoors: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesslevels'),
    content: <>hi</>,
  });
  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('addDoor'),
      content: <AddDoorModal closeModal={() => setModalOpen(false)} />,
    });
  };

  // const submit = () => {
  //   // Handle submit logic
  //   console.log(levelDetails);
  //   closeModal();
  // };

  return (
    <div className="add-level-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="levelName"
          name="levelName"
          label="Level Name"
          type="text"
          value={levelDetails.levelName}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          id="timeZone"
          name="timeZone"
          label="Time Zone"
          value={levelDetails.timeZone}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            { label: '24 - Hour Accessible', id: '24 - Hour Accessible' },
            { label: 'PEKERJA', id: 'PEKERJA' },
            { label: 'Full Day Access', id: 'Full Day Access' },
            { label: 'Access Time', id: 'Access Time' },
            { label: '7 to 16', id: '7 to 16' },
          ]}
        />
        <SelectBoxField
          id="area"
          name="area"
          label="Area"
          value={levelDetails.area}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            { label: 'ZK Teco', id: 'ZK Teco' },
            { label: 'Popy', id: 'Popy' },
            { label: 'Xiao Copy', id: 'Xiao Copy' },
            { label: 'Check ZK', id: 'Check ZK' },
          ]}
        />
      </div>

      <div className="row-add">
        <Button onClickFunction={addDoor} title="+ Add Door" className="btn-secondary" />
        <span>No of Doors: {levelDetails.noOfDoors}</span>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => closeModal()}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default AddLevelModal;

import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import IssueCardExportData from './components/export-data';
import { getOperateTypeAction } from '../../utils/helpers';
// Import card slice actions
import { fetchIssueCardList, setSearchFilter } from '../../redux/slices/IssueCardSlice';

function IssueCardRecord() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { issueCards, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.issueCard,
  );
  const columnsField = [
    {
      accessorKey: 'cardNo',
      header: t('cardNumber'),
      size: 150,
    },
    {
      accessorKey: 'pin',
      header: t('personnelId'),
      size: 150,
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'operateType',
      header: t('action'),
      size: 150,
      Cell: ({ cell }: { cell: any }) => getOperateTypeAction(cell.getValue()),
    },
    {
      accessorKey: 'operator',
      header: t('operator'),
      size: 150,
    },
    {
      accessorKey: 'issueTime',
      header: t('time'),
    },
    {
      accessorKey: 'changeTime',
      header: t('changeTime'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('exportIssueCardRecord'),
    content: <>hi</>,
  });
  const fetchData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchIssueCardList({ ...params }));
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (issueCards.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);
  return (
    <div className="issue-card-record">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('issueCardRecord')}
        broadCram={[
          {
            title: t('cardManagement'),
          },
          {
            title: t('issueCardRecord'),
          },
        ]}
      />
      <CardBox
        title={t('listOfIssueCardRecord')}
        rightSide={
          <CardRightButtonGroup
            buttonLabel={t('export')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('exportIssueCardRecord'),
                content: (
                  <IssueCardExportData
                    closeModal={() => setModalOpen(false)}
                    submitFunction={exportData}
                  />
                ),
              });
            }}
          />
        }>
        <DataTable
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          fetchNextPage={fetchNextData}
          values={issueCards}
          fixedColumn={['']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default IssueCardRecord;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Adjust this import to your API file location
import cardAPI from '../../utils/api/card';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';

// Import the date formatting function
import { changeDateTimeFormat } from '../../utils/helpers';

// Define the type for a Card object
interface Card {
  id: string;
  pin: string;
  createTime: string;
  personPin: string;
  personName: string;
  personLastName: string;
  departmentNo: string;
  // Add any other properties that the card object may have
}

// Thunk for fetching the card list
export const fetchCardList = createAsyncThunk(
  'cards/fetchCardList',
  async (data: any, { dispatch }) => {
    try {
      const response = await cardAPI.getCards(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.list.map((val: any) => ({
            ...val,
            id: val.id,
            createTime: changeDateTimeFormat(val.createTime),
          })),
          size: response.data.data.size,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

// Thunk for fetching unissued cards
export const fetchUnIssuedCards = createAsyncThunk(
  'cards/fetchUnIssuedCards',
  async (data: any, { dispatch }) => {
    try {
      const response = await cardAPI.unIssuedCards(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.map((val: any) => ({
            ...val,
            id: val.id,
            createTime: changeDateTimeFormat(val.createTime),
          })),
          size: response.data.data.size,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

// Thunk for issuing a card
export const fetchBatchIssueCard = createAsyncThunk(
  'cards/issueCard',
  async (data: { cardNo: string }, { dispatch }) => {
    try {
      const response = await cardAPI.batchIssueCard(data.cardNo); // Modify based on your API structure
      return response.data;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

// Initial state for the slice
interface CardState {
  cards: Card[]; // List of issued cards
  unIssuedCards: Card[]; // List of unissued cards
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
  unIssuedPerson: any;
}

const initialState: CardState = {
  cards: [],
  unIssuedCards: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  unIssuedPerson: [],
};

// Create the slice
export const cardSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
    batchCardLoss: (state, action: any) => {
      // Filter out the deleted card from the `cards` list based on its id
      state.cards = state.cards.filter((val: { id: string }) => val.id !== action.payload);
    },
    // Reducer for deleting an unissued card from unIssuedPerson by card.id
    deleteFromUnIssuedPerson: (state, action: any) => {
      const cardId = action.payload;
      state.unIssuedCards = state.unIssuedCards.filter((card: any) => card.id !== cardId);
    },
  },
  extraReducers: (builder) => {
    // Handle fetchCardList actions
    builder
      .addCase(fetchCardList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCardList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.currentPageNo === 1) {
            state.cards = action.payload.data.list;
          } else {
            state.cards = [...state.cards, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchCardList.rejected, (state) => {
        state.status = 'failed';
      });

    // Handle fetchUnIssuedCards actions
    builder
      .addCase(fetchUnIssuedCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUnIssuedCards.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          // Assuming the unissued cards are stored similarly to the issued ones
          state.unIssuedCards = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchUnIssuedCards.rejected, (state) => {
        state.status = 'failed';
      });

    // Handle issueCard actions
    builder
      .addCase(fetchBatchIssueCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBatchIssueCard.fulfilled, (state, action: PayloadAction<any>) => {
        const issuedCard = action.payload.data;
        if (action.payload.code === 0) {
          // Remove the card from the unIssuedCards list
          state.unIssuedCards = state.unIssuedCards.filter((card) => card.id !== issuedCard.id);

          // Add the card to the issued cards list
          state.cards.push(issuedCard);
        }
        state.status = 'success';
      })
      .addCase(fetchBatchIssueCard.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, batchCardLoss, deleteFromUnIssuedPerson } = cardSlice.actions;

export default cardSlice.reducer;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../shared-components/swapTables/swapTable';

interface DeviceProps {
  partitionName: string;
  belongingIntrusionDevice: string;
}
function AddPartition() {
  const { t } = useTranslation();
  const tableColumns = [
    { label: t('partitionName'), id: 'partitionName' },
    { label: t('belongingIntrusionDevice'), id: 'belongingIntrusionDevice' },
  ];
  const [device, setDevice] = useState<DeviceProps[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<DeviceProps[]>([]);
  const changeSearch = () => {
    console.log('search');
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: DeviceProps[], newSelectedData: DeviceProps[]) => {
    setDevice(newAvailableData); // Update available data
    setSelectedDevice(newSelectedData); // Update selected data
  };
  return (
    <div className="add-partition">
      <SwapTable
        searchValue=""
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={device}
        selectedTableData={selectedDevice}
        onTableSwap={onTableSwap}
      />
    </div>
  );
}

export default AddPartition;

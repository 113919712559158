import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import AddZone from './components/AddZone';
// import AddHolidays from './components/add-holidays';

function ZoneList() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'zone',
      header: t('zone'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.zone}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];
  handleEditOpen = (editData: any) => {
    console.log(editData);
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <>hi</>,
      });
    }
  };
  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteZone'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={() => {}}
            />
          ),
        });
      },
    },
  ];
  const moreActionButton = [
    {
      title: t('whatRulesApplyInside'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('whatRulesApplyInside'),
          content: <>hi</>,
        });
      },
    },
  ];
  const tempData = [
    {
      name: 'outside',
      zone: '1',
      remarks: 'outside',
      id: '1',
    },
  ];
  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('zone'),
          },
        ]}
      />
      <CardBox
        title={t('listOfZones')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddZone closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          // getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          // isFetching={status === 'loading'} // Placeholder status
          values={tempData} // Use the actual data here
          fixedColumn={['zone']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default ZoneList;

import { post } from '../base';

export default {
  getReaders: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/reader/getListByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  isExist: (data: any) => post(`${process.env.REACT_APP_API_URL}api/reader/isExist/`, {}, data),
  isExistVidChannel: () => post(`${process.env.REACT_APP_API_URL}api/reader/isExistVid`, {}, {}),
  saveReader: (data: any) => post(`${process.env.REACT_APP_API_URL}api/reader/save`, data, {}),
};

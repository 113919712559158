/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import accessByDepartment from '../../utils/api/AccessRule/accessByDepartment';
import { DepartmentState } from '../../interface/accessByDepartment.interface';

// Thunk for fetching the department list
export const fetchAccessByDepartment = createAsyncThunk(
  'cards/fetchAccessByDepartment',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessByDepartment.getAccessByDepartment(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

// Initial state for the slice

const initialState: DepartmentState = {
  departments: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  deptId: '',
};

// Create the slice
export const accessByDepartmentSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchAccessByDepartment actions
    builder
      .addCase(fetchAccessByDepartment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccessByDepartment.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.currentPageNo === 1) {
            state.departments = action.payload.data.list;
            state.deptId = action.payload.data.list?.[0]?.id;
          } else {
            state.departments = [...state.departments, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchAccessByDepartment.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = accessByDepartmentSlice.actions;

export default accessByDepartmentSlice.reducer;

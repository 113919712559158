import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';

interface ModalType {
  closeModal: () => void;
  addTriggerCondition: (val: any) => void;
  initialValues: string[];
}
interface DoorEventProps {
  id: string;
  eventName: string;
}
function LinkageTriggerConnections({ closeModal, addTriggerCondition, initialValues }: ModalType) {
  const { t } = useTranslation();
  const [doorEvent, setDoorEvent] = useState<string[]>([]);
  const [inputEvent, setInputEvent] = useState<string[]>([]);
  const [selectedDeviceEvents, setSelectedDeviceEvents] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<DoorEventProps[]>([]);
  const data = [
    {
      id: '0',
      eventName: 'Normal verify open',
    },
    { id: '1', eventName: 'Verify During Passage Mode TIme Zone' },
    { id: '2', eventName: 'First - Personnel Open' },
  ];
  const auxInputEvent = [
    {
      id: 'Inactive',
      eventName: 'Inactive Auxiliary Input Time Zone',
    },
    { id: 'Disconnected', eventName: 'Auxiliary Input Disconnected (Open)' },
    { id: 'Shorted', eventName: 'Auxiliary Input Shorted (Closed)' },
  ];
  const deviceEvent = [
    {
      id: 'auxiliary',
      eventName: 'Auxiliary Input Shorted (Closed)',
    },
  ];
  // Handler for selecting rows
  const handleRowSelection = (selectedRows: string[], tableType: string) => {
    // Clear selections from all tables
    setDoorEvent([]);
    setInputEvent([]);
    setSelectedDeviceEvents([]);
    setSelectedFullData([]); // Clear selected full data

    // Set selected rows for the active table and update full data accordingly
    if (tableType === 'doors') {
      setDoorEvent(selectedRows);
      const fullData = data.filter((item) => selectedRows.includes(item.id));
      setSelectedFullData(fullData);
    } else if (tableType === 'inputEvent') {
      setInputEvent(selectedRows);
      const fullData = auxInputEvent.filter((item) => selectedRows.includes(item.id));
      setSelectedFullData(fullData);
    } else if (tableType === 'selectedDeviceEvents') {
      setSelectedDeviceEvents(selectedRows);
      const fullData = deviceEvent.filter((item) => selectedRows.includes(item.id));
      setSelectedFullData(fullData);
    }
  };

  const submit = () => {
    if (selectedFullData?.length) {
      addTriggerCondition(selectedFullData);
      closeModal();
    }
  };
  useEffect(() => {
    if (initialValues.length > 0) {
      const checkDoorEvent = data.some((item) => initialValues.includes(item.id));
      const checkInputEvent = auxInputEvent.some((item) => initialValues.includes(item.id));
      const checkDeviceEvent = deviceEvent.some((item) => initialValues.includes(item.id));
      if (checkDoorEvent) {
        setDoorEvent(initialValues);
      }
      if (checkInputEvent) {
        setInputEvent(initialValues);
      }
      if (checkDeviceEvent) {
        setSelectedDeviceEvents(initialValues);
      }
    }
  }, [initialValues]);
  return (
    <div className="linkage-trigger-connections">
      <div className="table-container">
        <div className="table-wrapper">
          <h4 className="table-title">{t('doorEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'eventName' }]}
            value={data}
            checkBoxStatus
            selectedValues={doorEvent}
            onSelect={(row) => handleRowSelection(row, 'doors')}
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
          />
        </div>
        <div className="table-wrapper">
          <h4 className="table-title">{t('auxiliaryInputEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'eventName' }]}
            value={auxInputEvent}
            checkBoxStatus
            selectedValues={inputEvent}
            onSelect={(row) => handleRowSelection(row, 'inputEvent')}
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
          />
        </div>
        <div className="table-wrapper">
          <h4 className="table-title">{t('deviceEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'eventName' }]}
            value={deviceEvent}
            checkBoxStatus
            selectedValues={selectedDeviceEvents}
            onSelect={(row) => handleRowSelection(row, 'selectedDeviceEvents')}
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
          />
        </div>
      </div>
      <div className="form-buttons-right add-margin">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('done')} className="btn-primary" />
      </div>
    </div>
  );
}

export default LinkageTriggerConnections;

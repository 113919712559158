import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SwapTable from '../../../shared-components/swapTables/swapTable';

function AddDoor({ closeModal }: any) {
  const { t } = useTranslation();
  const initialData = [
    {
      id: '0',
      doorName: '192.168.20.112',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
    {
      id: '1',
      doorName: 'door 2',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
    {
      id: '2',
      doorName: 'door 3',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
  ];
  const [doors, setDoors] = useState<any[]>(initialData); // Available doors
  const [selectedDoors, setSelectedDoors] = useState<any[]>([]); // Selected doors
  const [search, setSearch] = useState('');
  // const [selectedDoorIds, setSelectedDoorIds] = useState<string[]>([]); // Track selected door IDs for "doors" table
  // const [selectedSelectedDoorIds, setSelectedSelectedDoorIds] = useState<string[]>([]); // Track selected door IDs for "selectedDoors" table
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'ownedDevice' },
    { label: t('serialNumber'), id: 'serialNumber' },
  ];

  // Function to handle table swapping
  const onTableSwap = (
    newAvailableData: any[], // temp
    newSelectedData: any[], // temp
  ) => {
    setDoors(newAvailableData); // Update available data
    setSelectedDoors(newSelectedData); // Update selected data
  };

  return (
    <div className="map-add-door">
      <SwapTable
        searchValue={search}
        changeSearch={(name: string, val: any) => setSearch(val)}
        tableColumns={tableColumns}
        tableData={doors}
        selectedTableData={selectedDoors}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => console.log('submit')}
          title={t('done')}
          className="btn-primary"
          disabled={initialData.length === 0}
        />
      </div>
    </div>
  );
}

export default AddDoor;

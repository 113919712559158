import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputField from '../../../shared-components/input-field/input-field';
import Label from '../../../shared-components/label/Label';

function OutputPoint() {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    doorActionType: '',
    doorTimeDelay: '20',
    auxActionType: '',
    auxTimeDelay: '20',
  });
  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
  };
  return (
    <div className="flex-row column output-point">
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('door')} />
          </div>
          <div className="box" />
          <SelectBoxField
            id="doorActionType"
            label={t('actionType')}
            value={value?.doorActionType}
            name="doorActionType"
            data={[
              { label: t('close'), id: 'close' },
              { label: t('open'), id: 'open' },
              { label: t('normallyOpen'), id: 'normallyOpen' },
            ]}
            onChangeFunction={(name, val) => onChangeValue(name, val.id)}
          />
          {value?.doorActionType === 'open' && (
            <InputField
              name="doorTimeDelay"
              label={t('actionTimeDelay')}
              id="doorTimeDelay"
              type="text"
              value={value.doorTimeDelay}
              onChangeFunction={onChangeValue}
              validation
              message="s(1-254)"
            />
          )}
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('auxiliaryInput')} />
          </div>
          <div className="box" />
          <SelectBoxField
            id="auxActionType"
            label={t('actionType')}
            value={value?.auxActionType}
            name="auxActionType"
            data={[
              { label: t('close'), id: 'close' },
              { label: t('open'), id: 'open' },
              { label: t('normallyOpen'), id: 'normallyOpen' },
            ]}
            onChangeFunction={(name, val) => onChangeValue(name, val.id)}
          />
          {value?.auxActionType === 'open' && (
            <InputField
              name="auxTimeDelay"
              label={t('actionTimeDelay')}
              id="auxTimeDelay"
              type="text"
              value={value.auxTimeDelay}
              onChangeFunction={onChangeValue}
              validation
              message="s(1-254)"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default OutputPoint;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import accessByLevel from '../../../utils/api/AccessRule/accessByLevel';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

interface ModalType {
  closeModal: () => void;
  personIds: string;
}
interface ApiParams {
  personIds: string;
  type: string;
}
interface PersonProps {
  id: string;
  personnelId: string;
  firstName: string;
  lastName: string;
  department: string;
}
function AddPersonnel({ closeModal, personIds }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = [
    {
      label: t('query'),
      id: 'query',
      value: 'query',
    },
    {
      label: t('department'),
      id: 'department',
      value: 'department',
    },
  ];

  const [type, setType] = useState('query');
  const [persons, setPersons] = useState<PersonProps[]>([]); // Available persons
  const [selectedPerson, setSelectedPerson] = useState<PersonProps[]>([]); // Selected persons
  const [deptName, setDeptName] = useState('');

  const tableColumns = [
    { label: t('personnelId'), id: 'personPin' },
    { label: t('firstName'), id: 'personName' },
    { label: t('lastName'), id: 'personLastName' },
    { label: t('department'), id: 'deptName' },
  ];

  const changeSearch = (event: any) => {
    setDeptName(event.target.value);
  };
  const fetchLevel = (params: ApiParams) => {
    accessByLevel
      .getListLevel(params)
      .then((res) => {
        setPersons(res.data.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: PersonProps[], newSelectedData: PersonProps[]) => {
    setPersons(newAvailableData); // Update available data
    setSelectedPerson(newSelectedData); // Update selected data
  };
  const submit = () => {
    const selectedIds = selectedPerson.map((item: PersonProps) => item.id).join(',');
    const req = {
      personIds,
      levelId: selectedIds,
    };
    accessByLevel
      .addPersonToLevel(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('levelAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchLevel({
      personIds,
      type: 'noSelected',
    });
  }, [personIds]);
  return (
    <div className="access-rule-add-personnel flex-row column">
      <RadioButtonField
        label=""
        name="recurring"
        alignment="row-wrap"
        data={options}
        value={type}
        onChangeFunction={(name, value: any) => setType(value)}
      />
      {type === 'department' && (
        <div className="select-input">
          <SelectBoxField
            id="deptName"
            label={t('departmentName')}
            value={deptName}
            name="deptName"
            data={[
              { label: 'Management', id: 'Management' },
              { label: 'Tower Management', id: 'Tower Management' },
            ]}
            onChangeFunction={(name: any, value: any) => setDeptName(value)}
          />
        </div>
      )}
      {type === 'query' && (
        <SwapTable
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={persons}
          selectedTableData={selectedPerson}
          onTableSwap={onTableSwap}
        />
      )}
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={selectedPerson.length === 0}
      />
    </div>
  );
}

export default AddPersonnel;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';
import { useAppDispatch } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import InputField from '../../shared-components/input-field/input-field';
import SelectBoxField from '../../shared-components/selectbox-field/selectbox-field';
import TabPages from '../../shared-components/tab-pages/tab-pages';
import { Search } from '../../assets/svgicons/svgicon';
import Door from './components/door-tab';
import auth from '../../utils/api/auth';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import Table from '../../shared-components/table/table';
import AuxiliaryInput from './components/auxiliary-input-tab';
import AuxiliaryOutput from './components/auxiliary-output-tab';

function RealTimeMonitoring() {
  const { t } = useTranslation();
  const [areaList, setAreaList] = useState([]);
  const dispatch = useAppDispatch();
  const WS_URL = 'ws://192.168.68.137:8080/websocket';

  const tabs = [
    {
      key: 'door',
      title: t('door'),
      content: <Door />,
    },
    {
      key: 'auxiliaryInput',
      title: t('auxiliaryInput'),
      content: <AuxiliaryInput />,
    },
    {
      key: 'auxiliaryOutput',
      title: t('auxiliaryOutput'),
      content: <AuxiliaryOutput />,
    },
  ];
  const [filterValue, setFilterValue] = useState({
    deviceName: '',
    Area: '',
    status: '',
  });
  let subscribeToEvent: any;
  const onChangeValue = (name: string, value: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const statusData = [
    {
      title: t('normal'),
      count: 0,
      color: 'success',
    },
    {
      title: t('alarm'),
      count: 0,
      color: 'danger',
    },
    {
      title: t('exception'),
      count: 0,
      color: 'warning',
    },
    {
      title: t('totalReceived'),
      count: 0,
    },
  ];
  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.list.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        setAreaList(authArea);
        setFilterValue((prev: any) => ({
          ...prev,
          area: authArea?.[0]?.id,
        }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  // const webSocketConnection = () => {
  const {
    sendMessage,
    lastMessage,
    // readyState,
    // connect,
  } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('WebSocket connection established.');
      subscribeToEvent();
    },
    onClose: (event) => {
      console.log('WebSocket connection closed.', event);
    },
    onError: (event) => {
      console.error('WebSocket error occurred:', event);
    },
    shouldReconnect: () => true,
  });
  // debugger;
  subscribeToEvent = () => {
    const subscriptionMessage = JSON.stringify({
      type: 'subscribe', // Type of message: subscribe to the topic
      destination: '/topic/accAlarmMonitor/getEventData', // Topic to subscribe to
    });
    sendMessage(subscriptionMessage); // Send the subscription message
  };

  useEffect(() => {
    const interval = setInterval(() => {
      subscribeToEvent();
    }, 6000); // 6 seconds interval

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [sendMessage]);

  console.log(lastMessage);
  useEffect(() => {
    if (lastMessage) {
      const eventData = JSON.parse(lastMessage.data); // Parse the incoming data
      console.log('Received event data:', eventData);
      // Process the event data as needed
    }
  }, [lastMessage]);

  useEffect(() => {
    getAuthAreaList();
  }, []);
  return (
    <div className="real-time-monitoring">
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('realTimeMonitoring'),
          },
        ]}
      />
      <div className="content">
        <CardBox>
          <div className="filters">
            <InputField
              name="deviceName"
              label={t('deviceName')}
              id="name"
              type="text"
              value={filterValue.deviceName}
              innerIconStatus
              InnerIcon={Search}
              onChangeFunction={onChangeValue}
            />
            <SelectBoxField
              name="Area"
              label={t('Area')}
              id="Area"
              value={filterValue.Area}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={areaList}
              className=""
            />
            <SelectBoxField
              name="status"
              label={t('status')}
              id="status"
              value={filterValue.status}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={[
                { label: t('online'), id: 'online' },
                { label: t('disable'), id: 'disable' },
                { label: t('offline'), id: 'offline' },
                { label: t('unknown'), id: 'unknown' },
              ]}
              className=""
            />
          </div>
        </CardBox>
        <CardBox>
          <TabPages tabs={tabs} defaultNumber={0} />
        </CardBox>
        <CardBox title={t('Real - Time Events')}>
          <div className="real-time-events">
            <div className="event-table">
              <Table
                header={[
                  { label: t('time'), id: 'time' },
                  { label: t('area'), id: 'area' },
                  { label: t('device'), id: 'device' },
                  { label: t('eventPoint'), id: 'eventPoint' },
                  { label: t('eventDescription'), id: 'eventDescription' },
                  { label: t('cardNumber'), id: 'cardNumber' },
                  { label: t('person'), id: 'person' },
                  { label: t('readerName'), id: 'readerName' },
                  { label: t('verificationMode'), id: 'verificationMode' },
                ]}
                value={[]} // Display the current issued cards
              />
            </div>
            <div className="status-container">
              {statusData.map((status: any) => (
                <div key={status.title} className="status-wrap">
                  {status?.color && <div className={`circle-dot ${status?.color}`} />}
                  <span style={{ color: '#696C6F' }}>
                    {status?.title} : {status?.count}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </CardBox>
      </div>
    </div>
  );
}

export default RealTimeMonitoring;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../assets/svgicons/svgicon';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import AddLinkage from './components/add-linkage';

function LinkageOutputPoint() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  let handleEditOpen: any;
  const data = [
    {
      name: 'Buzzer',
      deviceName: 'FA2000',
      linkageTriggerConditions: 'Extended Open Door Timeout',
      id: '1',
    },
    {
      name: 'Buzzer',
      deviceName: 'FA2000',
      linkageTriggerConditions: 'Extended Open Door Timeout',
      id: '2',
    },
    {
      name: 'Buzzer',
      deviceName: 'FA2000',
      linkageTriggerConditions: 'Extended Open Door Timeout',
      id: '3',
    },
  ];
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('linkageName'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
    },
    {
      accessorKey: 'linkageTriggerConditions',
      header: t('linkageTriggerConditions'),
    },
  ];
  handleEditOpen = (editData: any) => {
    console.log(editData);
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <AddLinkage closeModal={() => setModalOpen(false)} update={editData} />,
      });
    }
  };
  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };
  const deleteFunction = (id: string) => {
    console.log(id);
  };
  const deleteRecord = (val: any) => {
    setModalOpen(true);
    setModalView({
      title: t('deletePersonnel'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={val.id}
          okFunction={deleteFunction}
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => deleteRecord(val),
    },
  ];
  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('linkage'),
          },
        ]}
      />
      <CardBox
        title={t('listOfLinkage')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddLinkage closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          // getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          // isFetching={status === 'loading'} // Placeholder status
          values={data} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default LinkageOutputPoint;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';

type ModalType = {
  closeModal: () => void;
};
function CancelAlarmModal({ closeModal }: ModalType) {
  const { t } = useTranslation();

  return (
    <div className="remote-opening-modal">
      <div className="target-section">
        <label htmlFor="target">{t('target')} :</label>
        <span>{t('allDoors')}</span>
      </div>
      <div className="confirmation-text">{t('areYouWantToPerformTheCancelAlarmOperation')}</div>

      <div className="modal-footer">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default btn-bold" />
        <Button
          onClickFunction={() => {
            // Handle OK action here
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default CancelAlarmModal;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { LoadingProgressCount, sortValuesByStatus } from '../../../utils/helpers';
import deviceApi from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

function GetPersonalInformation({ closeModal, selectedValues, selectedId }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [responseMsg, setResponseMsg] = useState('');
  const [personOptions, setPersonOptions] = useState<any[]>([]);
  const [personalInformation, setPersonalInformation] = useState('0');
  const options = [
    {
      label: t('obtainPersonnelInformation'),
      id: '0',
      value: '0',
    },
    {
      label: t('obtainFingerprintInformation'),
      id: '1',
      value: '1',
    },
    {
      label: t('obtainFaceInformation'),
      id: '2',
      value: '2',
    },
    {
      label: t('obtainFingerVeinInformation'),
      id: '7',
      value: '7',
    },
    {
      label: t('obtainPalmInformation'),
      id: '8',
      value: '8',
    },
    {
      label: t('obtainPalmInformation'),
      id: '10',
      value: '10',
    },
    {
      label: t('obtainVisibleLightFaceInformation'),
      id: '9',
      value: '9',
    },
  ];
  const submitPersonInfo = () => {
    const request = {
      id: selectedId,
      dataType: personalInformation,
    };
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    deviceApi
      .uploadPersonInfo(request)
      .then(() => {
        setTimeout(() => {
          stopProgress();
          setLoading('0');
          setResponseMsg('true');
        }, 3000);
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const submit = () => {
    setLoading('1');
    deviceApi
      .synchronizeTime(selectedId)
      .then((response) => {
        if (response.data.success) {
          submitPersonInfo();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const onChangeValue = (name: string, value: any) => {
    setPersonalInformation(value);
  };
  const getPersonnelInfo = () => {
    deviceApi
      .getPersonInfo(selectedId)
      .then((res) => {
        const supportDevicePerson = res?.data?.data?.supportDevFun?.split(':');
        const filteredOptions = !selectedValues
          .map((device: any) => device.status)
          .includes('online')
          ? options.filter((item: any) => item.value === '0') // Default object filtering
          : options.filter(
              (item: any, index: number) =>
                item.value === '0' || supportDevicePerson[index] === '1', // Combine logic
            );

        setPersonOptions(filteredOptions);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    getPersonnelInfo();
  }, [selectedId]);
  /**
   * @sortValuesByStatus - Helper to sort devices based on their online/offline/disabled status
   * @popupErrorMessages - Helper to assign a message based on device status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const getOnlineDevice = !selectedDevices.map((data: any) => data?.status).includes('online');
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <div className="select-device-wrapper">
                    <RadioButtonField
                      label=""
                      name="status"
                      alignment="column"
                      data={[
                        {
                          label: `${device.deviceName}: `,
                          id: 'on',
                          value: 'on',
                        },
                      ]}
                      value="on"
                      onChangeFunction={() => {}}
                    />
                    <span className="active-name">{device.alias}</span>
                  </div>
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.alias)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disabled')
                    .map((item: any) => item?.alias)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <RadioButtonField
            label=""
            name="personalInformation"
            alignment="row-wrap"
            data={personOptions}
            value={personalInformation}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={getOnlineDevice || loading === '1'}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && responseMsg && (
                <div className="success-wrapper">
                  <p>Data Processing, Please Wait.....</p>
                  <p>Sending commands completed</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Get Personnel information successfully.
                  </p>
                  <p>No data are returned from the device.</p>
                  <p>Complete</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GetPersonalInformation;

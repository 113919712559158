import { post } from './base/index';

export default {
  getPendingReview: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/temp/getByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  deletePendingReview: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/temp/delete/${id}`, {});
  },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import TimeInputField from '../../../shared-components/time-input-field/TimeInputField';
import Button from '../../../shared-components/button/button';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

import {
  DayIntervalTimes,
  ModalType,
  StateProps,
  TimeStateProps,
} from '../../../interface/timezone.interface';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';
import { fetchTimeZone } from '../../../redux/slices/TimeZoneSlices';
import timezone from '../../../utils/api/AccessRule/timezone';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'holiday1',
  'holiday2',
  'holiday3',
];

const intervals = ['interval1', 'interval2', 'interval3'];
const initialTimeValues = () => {
  const initialState: DayIntervalTimes = {};
  days.forEach((day) => {
    initialState[day] = {};
    intervals.forEach((interval) => {
      initialState[day][interval] = {
        startTime: { hour: '00', minute: '00' },
        endTime: { hour: '00', minute: '00' },
      };
    });
  });
  return initialState;
};
const initialValues = { name: '', remarks: '', copySettings: [] };
function TimezoneTable({ closeModal, update }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [timeValues, setTimeValues] = useState<DayIntervalTimes>(initialTimeValues);
  const [values, setValues] = useState<StateProps>(update || initialValues);
  const [checkExistName, setCheckExistName] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const onChangeValue = (
    day: string,
    interval: string,
    field: 'startTime' | 'endTime',
    time: TimeStateProps,
  ) => {
    setTimeValues((prev) => ({
      ...prev,
      [day]: {
        ...prev?.[day],
        [interval]: {
          ...prev?.[day]?.[interval],
          [field]: time,
        },
      },
    }));
  };
  const handleChange = (name: string, value: any) => {
    setValues((prev: StateProps) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'copySettings') {
      const mondayValues = timeValues.monday;
      if (Object.keys(mondayValues)) {
        if (value.length > 0) {
          const updatedTimeValues = { ...timeValues };
          // Replicate Monday's values to Tuesday through Friday
          days.slice(1, 5).forEach((day) => {
            updatedTimeValues[day] = { ...mondayValues };
          });
          setTimeValues(updatedTimeValues);
        } else {
          const updatedTimeValues = { ...timeValues };
          days.slice(1, 5).forEach((day) => {
            Object.keys(updatedTimeValues[day]).forEach((interval) => {
              updatedTimeValues[day][interval] = {
                startTime: { hour: '00', minute: '00' },
                endTime: { hour: '00', minute: '00' },
              };
            });
          });
          setTimeValues(updatedTimeValues);
        }
      }
    }
  };
  const onBlur = async (name: string, value: any) => {
    if (name === 'name' && value !== '') {
      const responseData = await timezone.isExistName(value);
      setCheckExistName(responseData?.data);
    }
  };
  const saveTimeZone = (req: any, close?: string) => {
    timezone
      .addTimeZone(req)
      .then((response) => {
        if (response.data.success) {
          const message = update
            ? t('timezoneUpdatedSuccessfully')
            : t('timezoneAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(fetchTimeZone({ pageNo: 1 }));
          if (close) {
            closeModal();
          } else {
            setTimeValues(initialTimeValues);
            setValues(initialValues);
          }
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const submit = async (params?: string) => {
    setDisabled(true);
    const transformed: any = {};
    days.forEach((day) => {
      if (timeValues[day]) {
        Object.entries(timeValues[day]).forEach(([, interval], index) => {
          // Determine the prefix for holidays vs. regular days.
          const prefix = day.startsWith('holiday') ? `holidayType${day.slice(-1)}` : day;
          const startKey = `${prefix}Start${index + 1}`;
          const endKey = `${prefix}End${index + 1}`;
          transformed[startKey] = `${interval.startTime.hour}:${interval.startTime.minute}`;
          transformed[endKey] = `${interval.endTime.hour}:${interval.endTime.minute}`;
        });
      }
    });
    const request = {
      name: values.name,
      remarks: values.remarks,
      id: values?.id,
      ...transformed,
    };
    try {
      const response1 = await timezone.isDataValid(request);
      if (response1?.data?.data === 'true') {
        saveTimeZone(request, params);
      } else {
        setErrorMsg(response1?.data?.data);
      }
    } catch (error: any) {
      setDisabled(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  useEffect(() => {
    if (update?.timeZoneValues) {
      const result: any = {}; // The transformed structure.

      Object.entries(update.timeZoneValues).forEach(([key, value]: any) => {
        // Extract day, type (Start/End), and interval number from the key.
        const match = key.match(/([a-zA-Z]+(?:Type\d+)?)(Start|End)(\d+)/);
        if (match) {
          /**
           * Extracts the day, type (Start/End), and interval from the match.
           * The match contains the full key like "mondayStart1" and is sliced into day, type, and interval.
           *
           * @param match - The result of the key.match() which splits the key into day, type, and interval.
           * @returns {Array} - An array where:
           *   - day: The day (e.g., "monday", "holiday1")
           *   - type: "Start" or "End"
           *   - interval: The interval number (e.g., 1, 2, 3)
           */
          const [day, type, interval] = match.slice(1);
          // If the day contains "Type" part, remove it.
          const cleanedDay = day.replace('Type', '');
          /**
           * Ensures that the cleaned day exists in the result object.
           * If the day does not exist in the result, initialize it as an empty object.
           *
           * @param cleanedDay - The cleaned-up day name (e.g., "monday", "holiday1").
           * @returns {void}
           */
          if (!result[cleanedDay]) result[cleanedDay] = {};

          // Ensure the interval exists for the day.
          const intervalKey = `interval${interval}`;
          if (!result[cleanedDay][intervalKey]) {
            result[cleanedDay][intervalKey] = { startTime: {}, endTime: {} };
          }

          /**
           * Validates that the value (time) is in the correct format (HH:mm).
           * If valid, splits the time into hour and minute and assigns it to the appropriate time (start or end) in the result.
           *
           * @param value - The time value (e.g., "10:00", "23:59") for either start or end time.
           * @returns {void}
           */
          if (value && value.includes(':')) {
            const [hour, minute] = value.split(':');
            result[cleanedDay][intervalKey][`${type.toLowerCase()}Time`] = { hour, minute };
          }
        }
      });
      // Update state with the transformed result.
      setTimeValues(result);
    }
  }, [update]);

  useEffect(() => {
    if (values?.name && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values, checkExistName]);

  return (
    <div className="add-timeZone">
      <div className="form-row">
        <InputField
          id="name"
          name="name"
          label={t('timeZoneName')}
          type="text"
          value={values?.name}
          onBlurFunction={onBlur}
          onChangeFunction={handleChange}
          errorStatus={values.name !== '' && !checkExistName}
          message={t('nameIsAlreadyExist')}
          required
        />
        <InputField
          id="remarks"
          name="remarks"
          label={t('remarks')}
          type="text"
          value={values.remarks}
          onChangeFunction={handleChange}
        />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="date-time-header" colSpan={2} rowSpan={2}>
              <span>{t('dateTime')}</span>
            </th>
            {intervals.map((interval) => (
              <th key={interval} colSpan={2}>
                {t(interval)}
              </th>
            ))}
          </tr>
          <tr>
            {intervals.map((interval) => (
              <React.Fragment key={interval}>
                <th>{t('startTime')}</th>
                <th>{t('endTime')}</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {days.map((day) => (
            <tr key={day}>
              <td colSpan={2}>{t(day)}</td>
              {intervals.map((interval) => (
                <React.Fragment key={interval}>
                  <td>
                    <TimeInputField
                      value={timeValues[day][interval].startTime}
                      onChange={(time) => onChangeValue(day, interval, 'startTime', time)}
                      hourName={`${day}-${interval}-start-hour`}
                    />
                  </td>
                  <td>
                    <TimeInputField
                      minuteName={`${day}-${interval}-start-minute`}
                      value={timeValues?.[day]?.[interval]?.endTime}
                      onChange={(time) => onChangeValue(day, interval, 'endTime', time)}
                    />
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="checkbox-row">
        <CheckBoxInputField
          name="copySettings"
          data={[
            {
              label: t('copyMondaySettingstoOthersWeekdays'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={values?.copySettings}
          onChangeFunction={handleChange}
          alignment="column"
        />
      </div>
      {errorMsg && (
        <div className="text-danger flex-row center">
          <AttentionIcon />
          {errorMsg}
        </div>
      )}
      <div className="form-buttons-right">
        {!update && (
          <Button
            onClickFunction={() => submit('saveAndNew')}
            title={t('save&New')}
            className="btn-primary"
            disabled={disabled}
          />
        )}
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default TimezoneTable;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { AcknowledgeIcon, EditNotificationIcon } from '../../../assets/svgicons/svgicon';
// import Table from '../../../shared-components/table/table';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import Acknowledge from './acknowledge';
import AlarmProcessingHistory from './alarmProcessingHistory';
import { fetchMonitoringList } from '../../../redux/slices/AlarmMonitoringSlices';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import DataTable from '../../../shared-components/data-table/data-table';
import { EventData } from '../../../interface/alarmMonitoring.interface';

function RecordsTable() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { deviceMonitoringList, status } = useAppSelector((state: any) => state.alarmMonitoring);
  const tabs = [
    {
      key: 'all',
      title: t('all'),
    },
    {
      key: 'unconfirmed',
      title: t('unconfirmed'),
    },
    {
      key: 'processing',
      title: t('processing'),
    },
    {
      key: 'confirmed',
      title: t('confirmed'),
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [tab, setTab] = useState<string>('all');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<EventData[]>([]);
  const [recordData, setRecordData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchFilter, setSearchFilter] = useState({});
  const moreActionButton: any = [
    {
      title: t('acknowledge'),
      icon: <AcknowledgeIcon />,
      clickFunction: (): void => {
        if (selectedRows.length === 1) {
          setModalView({
            title: t('acknowledge'),
            content: (
              <Acknowledge
                closeModal={() => setModalOpen(false)}
                data={selectedFullData}
                id={selectedRows}
              />
            ),
          });
          setModalOpen(true);
        } else if (selectedRows.length > 1) {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      },
    },
    {
      title: t('alarmProcessingHistory'),
      icon: <EditNotificationIcon />,
      clickFunction: (): void => {
        if (selectedRows.length === 1) {
          setModalView({
            title: t('alarmProcessingHistory'),
            content: (
              <AlarmProcessingHistory
                closeModal={() => setModalOpen(false)}
                data={selectedFullData}
              />
            ),
          });
          setModalOpen(true);
        } else if (selectedRows.length > 1) {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      },
    },
  ];
  const tableColumns = [
    { accessorKey: 'time', header: t('time') },
    { accessorKey: 'device', header: t('device') },
    { accessorKey: 'eventPoint', header: t('eventPoint') },
    { accessorKey: 'eventName', header: t('eventDescription') },
    { accessorKey: 'person', header: t('person') },
    { accessorKey: 'belongAreas', header: t('belongAreas') },
    {
      accessorKey: 'priority',
      header: t('priority'),
      Cell: ({ row }: any) => {
        const getRowStyles = (priority: any) => {
          let backgroundColor = 'transparent';
          let color = 'inherit';
          if (priority === 'Danger') {
            backgroundColor = '#f411201a';
            color = '#f41120';
          } else if (priority === 'Medium') {
            backgroundColor = '#e0ffe0';
            color = '#03d733';
          } else if (priority === 'Weak') {
            backgroundColor = '#FF950033';
            color = '#FF9500';
          } else if (priority === 'Strong') {
            backgroundColor = '#3D93F51A';
            color = '#3D93F5';
          }
          return { backgroundColor, color };
        };
        const styles = getRowStyles(row.original.priority);
        const customStyle = {
          ...styles,
          width: 'max-content',
          borderRadius: '3px',
          padding: '3px',
        };
        return <div style={customStyle}>{row.original.priority}</div>;
      },
    },
    { accessorKey: 'status', header: t('status') },
  ];

  const fetchData = async () => {
    if (status === 'success') {
      dispatch(fetchMonitoringList());
    }
  };

  const changeTab = (tabKey: string) => {
    setTab(tabKey);
  };

  const getFilterData = (filter: any): any => {
    setSearchFilter(filter);
  };

  useEffect(() => {
    const filterData =
      tab !== 'all'
        ? deviceMonitoringList?.filter((table: any) => table?.status?.toLowerCase() === tab)
        : deviceMonitoringList;
    setRecordData(filterData);
    const filterColumn =
      tab !== 'all' ? tableColumns?.filter((column: any) => column?.id !== 'status') : tableColumns;
    setColumns(filterColumn);
  }, [tab]);

  useEffect(() => {
    setRecordData(deviceMonitoringList);
  }, [deviceMonitoringList]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="unconfirmed">
      <div className="tab-list-items">
        {tabs.map((tabItem: any) => (
          <div
            key={tabItem?.key}
            className={`tab-item ${tabItem.key === tab && 'active'}`}
            onClick={() => changeTab(tabItem.key)}
            role="presentation">
            {tabItem.title}
          </div>
        ))}
      </div>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="header-options">
        {moreActionButton?.map((item: any) => (
          <div
            key={item.title}
            className="option"
            role="presentation"
            onClick={item?.clickFunction}>
            <div className="label">
              {item.icon} {item?.title}
            </div>
          </div>
        ))}
      </div>
      <div className="table-wrapper">
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedRows} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedRows(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedFullData(val)}
          checkBoxActive
          values={recordData || deviceMonitoringList}
          enableDensityToggle={false}
          enableColumnPinning={false}
          enableHiding={false}
          enableColumnFilters={false}
          enableFullScreenToggle={false}
          fetchNextPage={(): any => {}}
          isFetching={status === 'loading'}
          totalRecords={recordData.length}
          height={150}
        />
      </div>
    </div>
  );
}

export default RecordsTable;

import React, { useEffect, useState } from 'react';

// Use translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import AddAttribute from './components/add-attribute';

// Use redux functions
import {
  deleteCustomAttribute,
  fetchCustomAttributeList,
  setSearchFilter,
} from '../../redux/slices/CustomAttributesSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use Interface
import { CustomAttributeReduxInterface } from '../../interface/attribute.interface';

// Use api's calls
import customAttributeApis from '../../utils/api/customAttribute';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

function CustomAttributes() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let sortTime = true;
  const {
    customAttribute,
    status,
    totalDataCount,
    currentPageNo,
    searchFilter,
  }: CustomAttributeReduxInterface = useAppSelector((state: any) => state.customAttribute);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'attrName',
      header: t('displayName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.attrName}
        </div>
      ),
    },
    {
      accessorKey: 'attrValue',
      header: t('attributeValue'),
    },
    {
      accessorKey: 'controlType',
      header: t('inputType'),
      Cell: ({ row }: any) => row.original.controlTypeLabel,
    },
    {
      accessorKey: 'positionX',
      header: t('row'),
    },
    {
      accessorKey: 'positionY',
      header: t('column'),
    },
    {
      accessorKey: 'personType',
      header: t('displayInPersonList'),
      Cell: ({ row }: any) => row.original.personTypeLabel,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });

  const fetchCustomAttributeData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchCustomAttributeList({ ...params }));
    }
  };

  const deleteRecord = (id: string) => {
    customAttributeApis
      .deleteCustomAttribute(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('customAttributeDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteCustomAttribute(id));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  handleEditOpen = (editData: any) => {
    setModalOpen(true);
    setModalView({
      title: t('editAttribute'),
      content: <AddAttribute closeModal={() => setModalOpen(false)} data={editData} />,
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteAttribute'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchCustomAttributeData({ pageNo: 1, ...filter.filter });
    }
  };
  const fetchData = (): any => {
    fetchCustomAttributeData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (customAttribute.length === 0 && sortTime) {
      sortTime = false;
      fetchCustomAttributeData({ pageNo: 1 });
    }
  }, []);
  return (
    <div className="attribute">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('customAttribute')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('customAttribute'),
          },
        ]}
      />
      <CardBox
        title={t('listOfCustomAttribute')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('addNewAttribute'),
                content: <AddAttribute closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          isFetching={status === 'loading'}
          values={customAttribute}
          fixedColumn={['attrName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default CustomAttributes;

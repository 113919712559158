import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import CommonPopup from './components/common-popup-auxiliary';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditReader from './components/edit-auxiliary';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import auxiliaryOutApi from '../../utils/api/Devices/auxiliaryOutput';
import { fetchAuxiliaryOutput, setSearchFilter } from '../../redux/slices/AuxiliaryOutputSlice';

function AuxiliaryOutput() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { auxiliaryOutput, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.auxiliaryOutput,
  );
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'devAlias',
      header: t('deviceName'),
    },
    {
      accessorKey: 'auxNo',
      header: t('number'),
    },
    {
      accessorKey: 'printerNumber',
      header: t('printedName'),
    },
    {
      accessorKey: 'accTimeSegName',
      header: t('passageModeTimeZone'),
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchAuxiliaryOutput({ ...params }));
    }
  };
  const [selectedDoor, setSelectedDoor] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: (
          <EditReader
            initialData={{
              deviceName: editData.devAlias,
              number: editData.auxNo,
              name: editData.name,
              printedName: editData.printerNumber,
            }}
            closeModal={() => setModalOpen(false)}
            saveChanges={async () => {
              const req = {
                name: editData.name,
                devAlias: editData.devAlias,
                auxNo: editData.auxNo,
                printerNumber: editData.printerNumber,
                owningBoard: editData.owningBoard,
                remarks: editData.remarks,
                accTimeSegName: editData.accTimeSegName,
                devSn: editData.devSn,
                id: editData.id,
              };
              try {
                const response = await auxiliaryOutApi.saveAuxOut(req);
                if (response.data.success) {
                  dispatch(
                    setNotificationError({
                      error: t('auxiliaryOutputUpdatedSuccessfully'),
                      status: 200,
                    }),
                  );
                  setModalOpen(false);
                } else {
                  dispatch(
                    setNotificationError({
                      error: response.data.msg,
                      status: response.data.ret,
                    }),
                  );
                }
              } catch (error) {
                dispatch(
                  setNotificationError({
                    error: t('failedToSaveChanges'),
                    status: 500,
                  }),
                );
              }
            }}
          />
        ),
      });
    }
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
  ];

  const moreActionButton = [
    {
      title: t('remoteOpen'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          console.log(selectedDoor);
          setModalView({
            title: t('remoteOpen'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="remoteOpen"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteClose'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteClose'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="remoteClose"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteNormallyOpen'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteNormallyOpen'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="normalOpenDoor"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (auxiliaryOutput?.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="auxiliary-input">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('auxiliaryOutput'),
          },
        ]}
      />
      <CardBox
        title={t('listOfauxiliaryOutput')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={() => {
              console.log('Main action button clicked');
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedDoor}
          getSelectedData={(val: any): any => setSelectedDoor(val)}
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          isFetching={false} // Placeholder status
          values={auxiliaryOutput} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AuxiliaryOutput;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';

type ModalType = {
  closeModal: () => void;
};
function RemoteOpeningModal({ closeModal }: ModalType) {
  const [levelDetails, setLevelDetails] = useState({
    openValue: '',
  });
  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const { t } = useTranslation();

  return (
    <div className="remote-opening-modal">
      <div className="target-section">
        <label htmlFor="target">{t('target')} :</label>
        <span>{t('allDoors')}</span>
      </div>
      <div className="input-section">
        <InputField
          id="openValue"
          name="openValue"
          label="Open"
          value={levelDetails.openValue}
          onChangeFunction={onChangeValue}
          type="number"
          min={1}
          max={254}
          className="input-number"
        />
        <div className="seconds">
          Second <span style={{ color: '#696C6F' }}>(1-254)</span>
        </div>
      </div>

      <div className="form-buttons-right">
        <Button
          onClickFunction={() => closeModal()}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default RemoteOpeningModal;

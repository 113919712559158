/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import DeviceSelectionModal from './deviceSelection-modal'; // Import the new modal component
import { getRS485Value, getSwitchStatesFromRS485 } from '../../../utils/helpers'; // Importing helpers
import GroupSwitchButton from '../../../shared-components/group-switch-button/group-switch-button'; // Import the GroupSwitchButton
import IoBoard from '../../../utils/api/Devices/IoBoard';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { ModalTypeInterface, ProtocolProps } from '../../../interface/accessIoBoard.interface';
import { fetchIoBoardList } from '../../../redux/slices/AccIOBoardSlices';

function AddIoBoard({ data, closeModal }: ModalTypeInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // State for form fields
  const [deviceConfig, setDeviceConfig] = useState(
    data || {
      name: '',
      deviceId: '',
      deviceName: '',
      protocolType: '',
      ioBoardType: '',
      rs485Address: '',
      rs485AddressCodeFigure: {
        switch1: false,
        switch2: false,
        switch3: false,
        switch4: false,
      },
    },
  );
  const [checkExistName, setCheckExistName] = useState(true);
  const [checkExistIp, setCheckExistIp] = useState(true);
  const [disable, setDisable] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  // const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [protocols, setProtocols] = useState([]);
  const [ioBoards, setIoBoards] = useState([]);

  const getIoBoardType = (id: string) => {
    const params = {
      devId: deviceConfig.deviceId,
      devProtocolType: id,
    };
    IoBoard.getExtBoardTypeSelects(params)
      .then((res) => {
        const ioBoardList = res.data.data.map((item: ProtocolProps) => ({
          label: item.text,
          id: item.value,
        }));
        setIoBoards(ioBoardList);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  // Handle form field changes
  const onChangeValue = (name: string, value: any) => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'protocolType') {
      getIoBoardType(value);
    }
  };

  const onBlur = async (name: string, value: any) => {
    if (name === 'name') {
      const responseData = await IoBoard.isExistAlias(value);
      setCheckExistName(responseData.data);
    }
  };
  const onBlurIp = async (name: string, value: any) => {
    if (name === 'rs485Address') {
      const responseData = await IoBoard.isExistIpAddress(value, deviceConfig?.deviceId);
      setCheckExistIp(responseData.data);
    }
  };

  // Handle RS485 Address change and update switch states accordingly
  const handleChangeRs485 = (name: string, value: any) => {
    const newValue = parseInt(value, 10);
    if (newValue > 15) {
      setDeviceConfig((prev: any) => ({
        ...prev,
        rs485Address: newValue,
      }));
      return;
    }
    const updatedSwitches = getSwitchStatesFromRS485(newValue); // Convert RS485 address to switch states
    setDeviceConfig((prev: any) => ({
      ...prev,
      rs485Address: newValue,
      rs485AddressCodeFigure: updatedSwitches,
    }));
  };

  // Handle switch toggle
  const changeSwitch = (switchName: string, value: boolean) => {
    setDeviceConfig((prevState: any) => {
      // Update the switch state
      const updatedSwitches = {
        ...prevState.rs485AddressCodeFigure,
        [switchName]: value,
      };
      // Calculate the new RS485 address value
      const newRS485Address = getRS485Value(updatedSwitches);
      // Update the state with the new switch values and RS485 address
      return {
        ...prevState,
        rs485AddressCodeFigure: updatedSwitches,
        rs485Address: newRS485Address,
      };
    });
  };

  // Submit function
  const submit = () => {
    // Handle form submission logic here
    const request = {
      id: deviceConfig?.id,
      alias: deviceConfig?.name,
      devId: deviceConfig?.deviceId,
      deviceAlias: deviceConfig?.deviceName,
      devProtocolType: deviceConfig?.protocolType,
      commAddress: deviceConfig?.rs485Address,
      extBoardType: deviceConfig?.ioBoardType,
    };
    IoBoard?.saveIoBoard(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = data ? t('IoBoardUpdatedSuccessfully') : t('IoBoardAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchIoBoardList({ pageNo: 1 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
    // closeModal();
  };

  const tableColumns = [
    { label: t('deviceName'), id: 'deviceName' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const getProtocols = (id: string) => {
    IoBoard.getDevProtocolTypeSelect(id)
      .then((res) => {
        const protocolList = res.data.data.map((item: ProtocolProps) => ({
          label: item.text,
          id: item.value,
        }));
        setProtocols(protocolList);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const getSelectedDevice = (selected: any[]) => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      deviceId: selected[0].id,
      deviceName: selected[0].deviceAlias,
    }));
    getProtocols(selected[0].id);
  };
  // Modal control
  const openDeviceSelection = () => {
    setModalView({
      title: t('selectLinkageDevice'),
      content: (
        <DeviceSelectionModal
          tableColumns={tableColumns}
          getSelected={getSelectedDevice}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };
  useEffect(() => {
    if (
      deviceConfig?.name &&
      deviceConfig?.ioBoardType &&
      deviceConfig?.rs485Address &&
      deviceConfig?.deviceId &&
      checkExistName &&
      checkExistIp
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [deviceConfig, checkExistName, checkExistIp]);
  useEffect(() => {
    if (data) {
      getProtocols(data?.deviceAlias);
      getIoBoardType(data?.ioBoardType);
      const updatedSwitches = getSwitchStatesFromRS485(data?.rs485Address); // Convert RS485 address to switch states
      setDeviceConfig((prev: any) => ({
        ...prev,
        rs485AddressCodeFigure: updatedSwitches,
      }));
    }
  }, [data]);
  return (
    <div className="add-device-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <div className="name-device-protocol-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={deviceConfig.name}
            errorStatus={!checkExistName}
            message={t('objectIsAlreadyExists')}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlur}
            required
          />
          <div className="select-device-wrapper">
            {!deviceConfig?.deviceId && (
              <Button
                onClickFunction={openDeviceSelection}
                title={t('selectDevice')}
                className="btn-default"
              />
            )}
            {deviceConfig?.deviceId && (
              <div className="device-name" onClick={openDeviceSelection} role="presentation">
                <h4 className="label">{deviceConfig?.deviceName}</h4>
              </div>
            )}
          </div>
          <div>
            <SelectBoxField
              id="protocolType"
              name="protocolType"
              label={t('protocolType')}
              value={deviceConfig.protocolType}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={protocols}
              required
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div>
          <SelectBoxField
            id="ioBoardType"
            name="ioBoardType"
            label={t('ioBoardType')}
            value={deviceConfig.ioBoardType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={ioBoards}
            required
          />
        </div>
        <InputField
          id="rs485Address"
          name="rs485Address"
          label={t('rs485Address')}
          type="number"
          value={deviceConfig.rs485Address}
          errorStatus={!checkExistIp}
          message={t('objectIsAlreadyExists')}
          onChangeFunction={handleChangeRs485}
          onBlurFunction={onBlurIp}
          required
        />
      </div>

      {/* GroupSwitchButton for RS485 Address Code Figure */}
      {/* Dynamic display instead of image */}
      <div className="info-message">
        <p>
          {t('rs485AddressCodeFigure')} {deviceConfig.rs485Address}
        </p>
      </div>
      <GroupSwitchButton
        label="" // Use translation for the label
        leftText={t('ON')}
        rightText={t('KE')}
        name="rs485AddressCodeFigure"
        value={deviceConfig?.rs485AddressCodeFigure} // Assuming you want to bind this to rs485Address
        changeFunction={changeSwitch}
        switchCount={4}
      />
      <div className="info-message">
        <p className="warning">{t('afterTheConfigurationYouNeedToRestartTheDeviceToTakeEffect')}</p>
      </div>
      <div className="form-buttons">
        <Button onClickFunction={() => closeModal()} title={t('Cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddIoBoard;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

function Whatsapp() {
  const { t } = useTranslation();
  const [sms, setSms] = useState('');
  return (
    <div className="intrusion">
      <div className="partition">
        <InputTextAreaField
          id="sms"
          value={sms}
          label={t('mobileNumber')}
          name="sms"
          placeHolder="Ex: 8854621423 ; 9854756213"
          onChangeFunction={(name, value) => setSms(value)}
        />
      </div>
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t('multipleNumber')}
      </div>
    </div>
  );
}

export default Whatsapp;

import { useDroppable } from '@dnd-kit/core';

// DropContainer Component
function DropContainer({ children, backgroundImage }: any) {
  const { setNodeRef } = useDroppable({
    id: 'dropzone',
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        backgroundSize: 'contain',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
      }}>
      {children}
    </div>
  );
}

export default DropContainer;

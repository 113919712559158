import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

interface AddPersonnelProps {
  closeModal: () => void;
}
interface AddPersonnelDevice {
  id: string;
  personnelId: string;
  firstName: string;
  lastName: string;
  department: string;
}
function AddPersonnel({ closeModal }: AddPersonnelProps) {
  const { t } = useTranslation();
  const options = [
    {
      label: t('query'),
      id: 'query',
      value: 'query',
    },
    {
      label: t('department'),
      id: 'department',
      value: 'department',
    },
  ];
  const initialData: AddPersonnelDevice[] = [
    {
      id: '0',
      personnelId: 'Test - 1',
      firstName: 'Test - 1',
      lastName: 'Test - 1',
      department: 'Test - 1',
    },
    {
      id: '1',
      personnelId: 'Test - 2',
      firstName: 'Test - 2',
      lastName: 'Test - 2',
      department: 'Test - 2',
    },
  ];
  const [type, setType] = useState('query');
  const [persons, setPersons] = useState<AddPersonnelDevice[]>(initialData); // Available persons
  const [selectedPerson, setSelectedPerson] = useState<AddPersonnelDevice[]>([]); // Selected persons
  const [deptName, setDeptName] = useState('');
  const tableColumns = [
    { label: t('personnelId'), id: 'personnelId' },
    { label: t('firstName'), id: 'firstName' },
    { label: t('lastName'), id: 'lastName' },
    { label: t('department'), id: 'department' },
  ];

  const changeSearch = () => {
    console.log('search');
  };
  // Function to handle table swapping
  const onTableSwap = (
    newAvailableData: AddPersonnelDevice[],
    newSelectedData: AddPersonnelDevice[],
  ) => {
    setPersons(newAvailableData); // Update available data
    setSelectedPerson(newSelectedData); // Update selected data
  };
  return (
    <div className="access-rule-add-personnel flex-row column">
      <RadioButtonField
        label=""
        name="recurring"
        alignment="row-wrap"
        data={options}
        value={type}
        onChangeFunction={(name, value: any) => setType(value)}
      />
      {type === 'department' && (
        <div className="select-input">
          <SelectBoxField
            id="deptName"
            label={t('departmentName')}
            value={deptName}
            name="deptName"
            data={[
              { label: 'Management', id: 'Management' },
              { label: 'Tower Management', id: 'Tower Management' },
            ]}
            onChangeFunction={(name: any, value: any) => setDeptName(value)}
          />
        </div>
      )}
      {type === 'query' && (
        <SwapTable
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={persons}
          selectedTableData={selectedPerson}
          onTableSwap={onTableSwap}
        />
      )}
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={() => closeModal()}
        disabled={selectedPerson.length === 0}
      />
    </div>
  );
}

export default AddPersonnel;

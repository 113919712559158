import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { PermissionInterface, Level } from '../../../interface/permissions.interface';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import AddLevelModal from './add-level-modal';
import ModalBox from '../../../shared-components/modal-box/modal-box';

function CardSetting({ values }: PermissionInterface) {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    ...values,
    general: values.general ?? false,
    levelSettings: [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('Add to Levels'),
    content: <>hi</>,
  });
  const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const handleAddLevel = (newLevels: any[]) => {
    setSelectedLevels(newLevels);
  };

  const checkboxTreeData = [
    { id: '1', label: t('Level 3'), value: 'my test', children: [] },
    { id: '2', label: t('My Testing'), value: '12 hours', children: [] },
    // Add other items as needed
  ];

  const checkboxData = selectedLevels.map((level) => ({
    label: level.levelName,
    value: level.id,
    id: level.id,
  }));

  useEffect(() => {}, [value]);

  return (
    <div className="permissions">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="permissions-form">
        <div className="row-form">
          <div className="item item1">
            <label htmlFor="level-settings" className="radio-label">
              {t('Level Settings')}
              <span>*</span>
            </label>
            <div className="level-settings-container">
              <div className="level-settings-header">
                <CheckBoxInputField
                  name="levelSettings"
                  alignment="column"
                  position="left"
                  value={
                    checkboxData.length > 0 && value.levelSettings.length === checkboxData.length
                      ? ['general']
                      : []
                  }
                  data={[{ label: 'General', id: 'general', value: 'general' }]}
                  onChangeFunction={(name: string, changeValue: any) => {
                    console.log(changeValue);
                    onChange(
                      name,
                      checkboxData.map((val) => val.value),
                    );
                  }}
                />
                <button
                  className="add-button"
                  type="button"
                  onClick={() => {
                    setModalOpen(true);
                    setModalView({
                      title: t('Add to Levels'),
                      content: (
                        <AddLevelModal
                          onClose={() => setModalOpen(false)}
                          onAddLevel={handleAddLevel}
                          checkboxTreeData={checkboxTreeData} // Pass the data here
                        />
                      ),
                    });
                  }}>
                  {t('Add')}
                </button>
              </div>
              <div className="level-settings-body">
                <CheckBoxInputField
                  label=""
                  name="levelSettings"
                  alignment="column"
                  position="left"
                  required
                  data={checkboxData}
                  value={value.levelSettings || []}
                  onChangeFunction={(name, changeValue) => {
                    onChange(name, changeValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="item">
            <RadioButtonField
              label={t('superUser')}
              name="superUser"
              alignment="row"
              required
              data={[
                { label: t('yes'), id: 'yes', value: 'yes' },
                { label: t('no'), id: 'no', value: 'no' },
              ]}
              value={value.superUser}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item" />
          <div className="item" />
          <div className="item">
            <RadioButtonField
              label={t('deviceOperationRule')}
              name="deviceOperationRule"
              alignment="warp"
              required
              data={[
                {
                  label: t('ordinaryUser'),
                  id: 'ordinaryUser',
                  value: 'ordinaryUser',
                },
                {
                  label: t('enroller'),
                  id: 'enroller',
                  value: 'enroller',
                },
                {
                  label: t('administrator'),
                  id: 'administrator',
                  value: 'administrator',
                },
              ]}
              value={value.deviceOperationRule}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item item3">
            <CheckBoxInputField
              label=""
              name="type"
              alignment="column"
              position="right"
              required
              data={[
                {
                  label: t('extendPassage'),
                  id: 'extendPassage',
                  value: 'extendPassage',
                },
                {
                  label: t('accessDisabled'),
                  id: 'accessDisabled',
                  value: 'accessDisabled',
                },
                {
                  label: t('setValidTime'),
                  id: 'setValidTime',
                  value: 'setValidTime',
                },
              ]}
              value={value.type}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSetting;

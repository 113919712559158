import { fetch, post } from './base/index';

export default {
  getCards: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/card/getCardList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  exportCard: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Pers/api/card/exportCard`, 'POST', data);
  },
  batchCardLoss: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/card/batchCardLoss?ids=${data}`, {});
  },
  batchCardReactivation: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/card/batchCardReactivation?ids=${data}`,
      {},
    );
  },
  unIssuedCards: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/card/unIssuedPerson`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  batchIssueCard: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/card/isExist?cardNo=${data}`, {});
  },
  batchCardIssue: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/card/batchCardIssue`, data);
  },
};

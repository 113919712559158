import { post } from '../base/index';

export default {
  listByPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/holiday/listByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  editHolidays: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/holiday/save`, data, {});
  },

  saveHolidays: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/holiday/save`, data, {});
  },

  deleteHolidays: (id: string = '') =>
    post(`${process.env.REACT_APP_API_URL}api/holiday/delete?ids=${id}`, {}),
};

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Adjust this import to your API file location
import issueCard from '../../utils/api/issueCard';
// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';

// Import the date formatting function
import { changeDateTimeFormat } from '../../utils/helpers';

// Define the type for an IssueCard object
interface IssueCard {
  id: string;
  pin: string;
  issueTime: string;
  // Add any other properties that the issue card object may have
}

// Thunk for fetching the issue card list
export const fetchIssueCardList = createAsyncThunk(
  'issueCards/fetchIssueCardList',
  async (data: any, { dispatch }) => {
    try {
      const response = await issueCard.getIssueCards(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.list.map((card: any) => ({
            ...card,
            id: card.pin,
            issueTime: changeDateTimeFormat(card.issueTime),
          })),
          size: response.data.data.size,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

// Initial state for the slice
interface IssueCardState {
  issueCards: IssueCard[];
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
}

const initialState: IssueCardState = {
  issueCards: [], // Explicitly typing this as an array of IssueCard objects
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

// Create the slice
export const issueCardSlice = createSlice({
  name: 'issueCards',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIssueCardList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIssueCardList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.currentPageNo === 1) {
            state.issueCards = action.payload.data.list;
          } else {
            state.issueCards = [...state.issueCards, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchIssueCardList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = issueCardSlice.actions;

export default issueCardSlice.reducer;

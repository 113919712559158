import { fetch, post } from './base/index';

export default {
  getIssueCards: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/card/IssueCardRecord`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  exportCard: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Pers/api/card/exportIssueCard`, 'POST', data);
  },
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputField from '../../../shared-components/input-field/input-field';
import { Search } from '../../../assets/svgicons/svgicon';
import CardStatus from '../../../shared-components/card-status/cardStatus';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';

interface AuxiliaryData {
  status: string;
  device: string;
  number: string;
  title: string;
}

function AuxiliaryInput() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [auxiliaryInput, setauxiliaryInput] = useState<AuxiliaryData[]>([]);

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('device'),
      key: 'devAlias',
    },
    {
      label: t('number'),
      key: 'no',
    },
  ];

  const statusData = [
    {
      title: t('online'),
      count: auxiliaryInput.filter((item) => item.status === 'Online')?.length || 0,
      color: 'success',
    },
    {
      title: t('offline'),
      count: auxiliaryInput.filter((item) => item.status === 'Offline')?.length || 0,
      color: 'danger',
    },
    {
      title: t('disable'),
      count: auxiliaryInput.filter((item) => item.status === 'Disable')?.length || 0,
      color: 'warning',
    },
    {
      title: t('unknown'),
      count: auxiliaryInput.filter((item) => item.status === 'Unknown')?.length || 0,
      color: 'secondary',
    },
    {
      title: t('currentTotal'),
      count: auxiliaryInput?.length || 0,
    },
  ];

  const handleCardSelect = (index: string) => {
    setSelectedCards(
      (prevSelected: any) =>
        prevSelected.includes(index)
          ? prevSelected.filter((item: any) => item !== index) // Remove if already selected
          : [...prevSelected, index], // Add to selected list if not selected
    );
  };

  useEffect(() => {
    realtimeMonitoring
      .getAllAuxIn()
      .then((response) => {
        setauxiliaryInput(Array.isArray(response.data.data) ? response.data.data : []);
      })
      .catch((error) => {
        console.error('Error fetching auxiliary input data:', error);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setauxiliaryInput([]);
      });
  }, [dispatch]);

  return (
    <div className="rtm-door">
      <div className="search-row">
        <div className="search-input">
          <InputField
            name="search"
            label={t('search')}
            id="name"
            type="text"
            value={search}
            innerIconStatus
            InnerIcon={Search}
            onChangeFunction={(name: string, value: any) => setSearch(value)}
          />
        </div>
      </div>
      <div className="card-grid">
        {auxiliaryInput.map((item, index) => (
          <CardStatus
            key={item.title}
            data={item}
            header={header}
            index={index}
            onSelect={handleCardSelect}
            isSelected={selectedCards.includes(index)}
          />
        ))}
      </div>
      <div className="status-container">
        {statusData.map((status: any) => (
          <div className="status-wrap">
            {status?.color && <div className={`circle-dot ${status?.color}`} />}
            <span style={{ color: '#696C6F' }}>
              {status?.title} : {status?.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuxiliaryInput;

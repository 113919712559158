import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/eventType/listByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  saveEvent: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/eventType/edit`, data, {});
  },
  getMediaFileList: () => {
    return get(`${process.env.REACT_APP_API_URL}api/baseMediaFile/getMediaFileList`, {});
  },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import FileUpload from './file-upload';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AddMapInterface } from '../../../interface/map.interface';
import map from '../../../utils/api/Devices/map';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import auth from '../../../utils/api/auth';

function AddMap({ closeModal, update }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addMap, setAddMap] = useState<AddMapInterface>(
    update || {
      name: '',
      authAreaId: '',
      mapPath: null,
      // modifyPath: [],
    },
  );
  const [disable, setDisable] = useState<boolean>(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const onChangeValue = (name: string, value: any) => {
    setAddMap((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onBlur = async (name: string, value: any) => {
    if (name === 'name') {
      const responseData = await map.isExistName(value);
      setCheckExistName(responseData?.data?.data);
    }
  };
  const removeImage = () => {
    setAddMap((prev: any) => ({
      ...prev,
      mapPath: null,
    }));
  };
  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.list.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        setAreaList(authArea);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const submit = async () => {
    console.log('request', addMap);
    const { name, authAreaId, mapPath } = addMap;
    const request = {
      name,
      authAreaId,
      file: mapPath,
    };
    try {
      const response1 = await map.saveMap(request);
      if (response1?.data?.success) {
        closeModal();
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  useEffect(() => {
    if (addMap.name && addMap.authAreaId && addMap.mapPath && !checkExistName) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [addMap, checkExistName]);

  useEffect(() => {
    getAuthAreaList();
  }, []);
  console.log(addMap);
  return (
    <div className="add-map">
      <div className="flex-row">
        <InputField
          name="name"
          label={t('name')}
          id="name"
          type="text"
          value={addMap.name}
          innerIconStatus={false}
          onChangeFunction={onChangeValue}
          onBlurFunction={onBlur}
          errorStatus={!checkExistName}
          required
        />
        <SelectBoxField
          name="authAreaId"
          label={t('areaName')}
          id="authAreaId"
          value={addMap.authAreaId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={areaList}
          className=""
          required
        />
      </div>
      {update && (
        <CheckBoxInputField
          name="modifyPath"
          data={[
            {
              label: t('modifyPath'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          position="right"
          value={addMap?.modifyPath}
          onChangeFunction={onChangeValue}
        />
      )}
      <FileUpload
        label={t('mapPath')}
        name="mapPath"
        value={addMap.mapPath}
        removeFunction={removeImage}
        onChangeFunction={onChangeValue}
        required
      />
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('save')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddMap;

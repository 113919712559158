import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import AddDepartment from './components/add-department';

// Use redux functions
import {
  deleteDepartment,
  fetchDepartmentList,
  setSearchFilter,
} from '../../redux/slices/DepartmentSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use api's calls
import departmentApis from '../../utils/api/department';

// Use Interface
import { DepartmentReduxInterface } from '../../interface/department.interface';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DepartmentExportData from './components/export-data';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import TabModalBox from '../../shared-components/tab-modal-box/tab-modal-box';
import DepartmentImportData from './components/import-data';
import DownloadDepartmentImportTemplate from './components/download-department-import-template';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

function Department() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let sortTime = true;
  const {
    departments,
    status,
    totalDataCount,
    currentPageNo,
    searchFilter,
  }: DepartmentReduxInterface = useAppSelector((state: any) => state.departments);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('departmentNumber'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.code}
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: t('departmentName'),
    },
    {
      accessorKey: 'parentCode',
      header: t('parentDepartmentNumber'),
    },
    {
      accessorKey: 'parentName',
      header: t('parentDepartmentName'),
    },
    {
      accessorKey: 'creationDate',
      header: t('creationDate'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });

  const fetchDepartmentData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchDepartmentList({ ...params }));
    }
  };
  const deleteRecord = (id: string) => {
    departmentApis
      .deleteDepartment(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('departmentDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteDepartment(id));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  handleEditOpen = (editData: any) => {
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: (
        <AddDepartment
          closeModal={() => setModalOpen(false)}
          data={{ ...editData, update: true }}
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = (data: any) => {
    console.log(data);
    setTabModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('exportDepartment'),
          content: (
            <DepartmentExportData
              closeModal={() => setTabModalOpen(false)}
              submitFunction={exportData}
            />
          ),
        });
      },
    },
    {
      title: t('import'),
      clickFunction: (): void => {
        setTabModalOpen(true);
      },
    },
  ];

  const importData = (data: any) => {
    console.log(data);
    setTabModalOpen(false);
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchDepartmentData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchData = (): any => {
    fetchDepartmentData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (departments.length === 0 && sortTime) {
      sortTime = false;
      fetchDepartmentData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TabModalBox
        status={tabModalOpen}
        closeModal={() => setTabModalOpen(false)}
        title={t('import')}
        tabs={[
          {
            title: t('importDepartment'),
            content: (
              <DepartmentImportData
                closeModal={() => setTabModalOpen(false)}
                submitFunction={importData}
              />
            ),
            key: 'importDeportment',
          },
          {
            title: t('downloadDepartmentImportTemplate'),
            content: (
              <DownloadDepartmentImportTemplate
                closeModal={() => setTabModalOpen(false)}
                submitFunction={importData}
              />
            ),
            key: 'downloadTemplate',
          },
        ]}
      />
      <TopHeader
        title={t('department')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('department'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDepartment')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('addNewDepartment'),
                content: <AddDepartment closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          isFetching={status === 'loading'}
          values={departments}
          fixedColumn={['code']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Department;

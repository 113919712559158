import { get, post } from '../base';

export default {
  getMonitoringList: () => {
    return post(`${process.env.REACT_APP_API_URL}api/alarmMonitor/getFirstPage`, {});
  },
  getDataByEventNum: () => {
    return post(`${process.env.REACT_APP_API_URL}api/alarmMonitor/getDataByEventNum`, {});
  },
  editAcknowledgeStatus: (data = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/alarmMonitor/editStatus`, {}, data);
  },
  showHistory: (id = {}) => {
    return get(`${process.env.REACT_APP_API_URL}api/alarmMonitor/showHistory/${id}`, {});
  },
};

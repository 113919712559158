import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditEventType from './components/edit-eventType';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import eventTypeApi from '../../utils/api/Devices/eventType';
import SetAudio from './components/set-audio';
import { fetchEventType, setSearchFilter } from '../../redux/slices/EventTypeSlice';

function EventType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { eventType, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.eventType,
  );

  const eventNameList = Array.from(new Set(eventType.map((data: any) => data.eventName)));
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('eventName'),
      filterVariant: 'multi-select',
      filterSelectOptions: eventNameList,
      filterFn: 'arrIncludesSome',
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'eventNo',
      header: t('eventNumber'),
      enableColumnFilter: false,
    },
    {
      accessorKey: 'eventLevel',
      header: t('eventLevel'),
      filterVariant: 'select',
      filterSelectOptions: Array.from(new Set(eventType.map((data: any) => data.eventLevel))),
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.eventLevel === 'Normal') {
          result = <span className="text-success">{row?.original?.eventLevel}</span>;
        } else if (row?.original?.eventLevel === 'Exception') {
          result = <span style={{ color: '#FF9500' }}>{row?.original?.eventLevel}</span>;
        } else {
          result = <span className="text-warning">{row?.original?.eventLevel}</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'devAlias',
      header: t('deviceName'),
      enableColumnFilter: false,
    },
    {
      accessorKey: 'devSn',
      header: t('serialNumber'),
      enableColumnFilter: false,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchEventType({ ...params }) as any);
    }
  };
  const [selectedEvent, setSelectedEvent] = useState<string[]>([]);
  handleEditOpen = (editEventType: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: (
          <EditEventType
            initialData={{
              deviceName: editEventType.devAlias,
              eventNumber: editEventType.eventNo,
              eventName: editEventType.name,
              eventLevel: editEventType.eventLevel,
              serialNumber: editEventType.devSn,
            }}
            closeModal={() => setModalOpen(false)}
            saveChanges={async () => {
              const req = {
                name: editEventType.name,
                eventNo: editEventType.eventNo,
                devAlias: editEventType.devAlias,
                auxNo: editEventType.auxNo,
                eventLevel: editEventType.eventLevel,
                devSn: editEventType.devSn,
                id: editEventType.id,
              };
              try {
                const response = await eventTypeApi.saveEvent(req);
                if (response.data.success) {
                  dispatch(
                    setNotificationError({
                      error: t('eventTypeUpdatedSuccessfully'),
                      status: 200,
                    }),
                  );
                  setModalOpen(false);
                } else {
                  dispatch(
                    setNotificationError({
                      error: response.data.msg,
                      status: response.data.ret,
                    }),
                  );
                }
              } catch (error) {
                dispatch(
                  setNotificationError({
                    error: t('failedToSaveChanges'),
                    status: 500,
                  }),
                );
              }
            }}
          />
        ),
      });
    }
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
  ];

  const moreActionButton = [
    {
      title: t('setAudio'),
      clickFunction: (): void => {
        if (selectedEvent.length > 0) {
          setModalView({
            title: t('setAudio'),
            content: (
              <SetAudio closeModal={() => setModalOpen(false)} selectedValues={selectedEvent} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (eventType?.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('eventType'),
          },
        ]}
      />
      <CardBox
        title={t('listOfReader')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          enableColumnFilters
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedEvent}
          getSelectedData={(val: any): any => setSelectedEvent(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          isFetching={false} // Placeholder status
          values={eventType} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default EventType;

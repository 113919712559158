import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../assets/svgicons/svgicon';
import AddMultiPersonGroup from './components/AddMultiPersonGroup';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import AddPersonnel from './components/AddPersonnel';

function MultiPersonGroup() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<string[]>([]);
  console.log(selectedFullData); // use after api integration
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('groupName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'id',
      header: t('personnelId'),
    },
    {
      accessorKey: 'firstName',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'department',
      header: t('department'),
    },
  ];
  const addPersonnel = (editData: any) => {
    console.log(editData);
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('addPersonnel'),
        content: <AddPersonnel closeModal={() => setModalOpen(false)} />,
      });
    }
  };
  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };
  handleEditOpen = (val: any) => {
    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: <AddMultiPersonGroup closeModal={() => setModalOpen(false)} data={val} />,
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: handleEditOpen,
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteMultiPersonGroup'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={() => setModalOpen(false)}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      function: (val: any) => addPersonnel(val),
    },
  ];
  const tempData = [
    {
      id: '0',
      name: 'Free Rotation - 1',
      remarks: 'ad',
    },
    {
      id: '1',
      name: 'Free Rotation - 2',
      remarks: 'ad',
    },
    {
      id: '2',
      name: 'Free Rotation - 3',
      remarks: 'ad',
    },
  ];
  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('multiPersonGroup'),
          },
        ]}
      />
      <CardBox
        header={t('openDoorGroup')}
        title={t('listOfDoors')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddMultiPersonGroup closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => setSelectedFullData(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          // isFetching={status === 'loading'} // Placeholder status
          values={tempData} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>{t('browsePersonnel')}</h4>
            <span className="sub-title">{t('listOfPersonnel')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={actionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={searchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            // getSelectedFullData={(val: any): any => setSelectedData(val)}
            checkBoxActive
            fetchNextPage={fetchNextPage}
            // isFetching={status === 'loading'} // Placeholder status
            values={[]} // Use the actual data here
            fixedColumn={['id']}
            totalRecords={0}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default MultiPersonGroup;

import React from 'react';

// For handling cookies
import Cookies from 'universal-cookie';

// Use interface
import { InputFieldInterFace } from '../../interface/common.interface';

function InputField({
  name,
  label,
  id,
  type,
  value,
  className,
  disabled,
  required = false,
  innerIconStatus,
  readonly = false,
  InnerIcon,
  message,
  errorStatus,
  validation,
  placeholder,
  innerIconFunction,
  onChangeFunction = () => {},
  onBlurFunction = () => {},
}: InputFieldInterFace) {
  const onKeyup = (e: any): void => {
    onChangeFunction(e.target.name, e.target.value);
  };
  const onBlur = (e: any): void => {
    onBlurFunction(e.target.name, e.target.value);
  };
  const cookies = new Cookies();
  const currentLanguageCode = cookies.get('i18next');
  return (
    <div className="form-item">
      <input
        type={type}
        id={id}
        className={`input_field ${className}`}
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        required
        name={name}
        onChange={onKeyup}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readonly}
        onWheel={(event) => event.currentTarget.blur()}
        min={0}
      />
      <label
        className={`input-label ${className} ${currentLanguageCode === 'ar' ? 'ar' : ''}`}
        htmlFor={id}>
        {label}
        {required && <span>*</span>}
      </label>
      {innerIconStatus && (
        <div
          className={`input-right-icon ${currentLanguageCode === 'ar' ? 'ar' : ''}`}
          onClick={innerIconFunction}
          role="presentation">
          <InnerIcon />
        </div>
      )}
      {errorStatus && <small className="input-error-message">{message}</small>}
      {validation && <small className="input-validation-message">{message}</small>}
    </div>
  );
}

export default InputField;

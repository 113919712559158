import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import AddSelectedDevice from './AddSelectedDevice';
import ModalBox from '../../../shared-components/modal-box/modal-box';

type ModalType = {
  closeModal: () => void;
};
function AddInterlock({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const [levelDetails, setLevelDetails] = useState({
    name: '',
    interlockRule: '',
    noOfDoors: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accessinterlock'),
    content: <>hi</>,
  });
  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('selecteDevice'),
      content: <AddSelectedDevice closeModal={() => setModalOpen(false)} />,
    });
  };

  // const submit = () => {
  //   // Handle submit logic
  //   console.log(levelDetails);
  //   closeModal();
  // };

  return (
    <div className="add-level-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <InputField
          id="name"
          name="name"
          label={t('name')}
          type="text"
          value={levelDetails.name}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          id="interlockRule"
          name="interlockRule"
          label={t('interlockRule')}
          value={levelDetails.interlockRule}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            {
              label: 'Interlock between GDTest192.71.120-1',
              id: 'Interlock between GDTest192.71.120-1',
            },
            {
              label: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
              id: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
            },
            {
              label: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
              id: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
            },
            {
              label: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
              id: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
            },
            {
              label: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
              id: 'Interlock between GDTest192.71.120-1 and GDTest192.168.71.120-2',
            },
          ]}
        />
      </div>

      <div className="row-device">
        <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-secondary" />
        <span className="selected-devices">
          {t('selectDevice')} {levelDetails.noOfDoors}
        </span>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('save&New')}
          className="btn-primary"
        />
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default AddInterlock;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import CommonPopup from './components/common-popup';
import ListDropdown from '../../shared-components/list-dropdown/list-dropdown';
import { OptionType } from '../../interface/common.interface';
import {
  ActivateLockdownIcon,
  DeactivateLockdownIcon,
  DisableIndradayIcon,
  EnableIndradayIcon,
} from '../../assets/svgicons/svgicon';
import EditDoor from './components/edit-door';
import { fetchDoorList, setSearchFilter } from '../../redux/slices/DoorSlice';
import door from '../../utils/api/door';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

function Door() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { totalDataCount, searchFilter, currentPageNo, doors, status } = useAppSelector(
    (state: any) => state.doors,
  );
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('doorName'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'deviceAlias',
      header: t('ownedDevice'),
    },
    {
      accessorKey: 'deviceSn',
      header: t('serialNumber'),
    },
    {
      accessorKey: 'enabled',
      header: t('enable'),
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.enabled) {
          result = <span style={{ color: '#00A76F' }}>Enabled</span>;
        } else {
          result = <span style={{ color: '#E8212E' }}>Disabled</span>;
        }
        return result;
      },
      size: 100,
    },
    {
      accessorKey: 'activeTimeSegName',
      header: t('activeTimezone'),
    },
    {
      accessorKey: 'doorSensorStatus',
      header: t('doorSensorType'),
      Cell: ({ row }: any) => {
        let result;
        if (row.original.doorSensorStatus === 1) {
          result = <div>Normally open</div>;
        } else {
          result = <div>None</div>;
        }
        return result;
      },
    },
    {
      accessorKey: 'verifyModeName',
      header: t('verificationMode'),
      Cell: ({ row }: any) => {
        let result;
        if (row.original.verifyModeName === 'common_verifyMode_onlyFp') {
          result = <div>FingerPrint</div>;
        } else if (row.original.verifyModeName === 'common_verifyMode_onlyCard') {
          result = <div>Access Card</div>;
        } else {
          result = <div>Automatic Identification</div>;
        }
        return result;
      },
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
  ];

  const options: OptionType[] = [
    {
      value: 'activateLockdown',
      label: 'activateLockdown',
      icon: ActivateLockdownIcon,
    },
    {
      value: 'deactivateLockdown',
      label: 'deactivateLockdown',
      icon: DeactivateLockdownIcon,
    },
    {
      value: 'enableIntraday',
      label: 'enableIntradayPassageModeTimeZone',
      icon: EnableIndradayIcon,
    },
    {
      value: 'disableIntraday',
      label: 'disableIntradayPassageModeTimeZone',
      icon: DisableIndradayIcon,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedDoor, setSelectedDoor] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);

  handleEditOpen = (editData: any) => {
    // const updateData = {
    //   doorNumber: editData.doorNo,
    //   operateInterval: editData.actionInterval,
    //   anti_passback_duration: editData.inApbDuration,
    //   ...editData,
    // };
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <EditDoor closeModal={() => setModalOpen(false)} data={{ ...editData }} />,
      });
    }
  };

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDoorList({ ...params }));
    }
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
  ];

  const handleClick = (value: string = '') => {
    if (selectedDoor.length > 0) {
      if (value === 'activateLockdown') {
        setModalView({
          title: t('activateLockdown'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedIds={selectedDoor}
              inputTrue
              modelName="lockDown"
            />
          ),
        });
        setModalOpen(true);
      } else if (value === 'deactivateLockdown') {
        setModalView({
          title: t('deactivateLockdown'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedIds={selectedDoor}
              inputTrue
              modelName="unLockDown"
            />
          ),
        });
        setModalOpen(true);
      } else if (value === 'enableIntraday') {
        setModalView({
          title: t('enableIntradayPassageModeTimeZone'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedIds={selectedDoor}
              inputTrue
              modelName="normalOpenDoor"
            />
          ),
        });
        setModalOpen(true);
      } else if (value === 'disableIntraday') {
        setModalView({
          title: t('disableIntradayPassageModeTimeZone'),
          content: (
            <CommonPopup
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedIds={selectedDoor}
              inputTrue
              modelName="enableNormalOpenDoor"
            />
          ),
        });
        setModalOpen(true);
      }
    } else {
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
      setModalOpen(true);
    }
  };

  const changeValue = (value: any) => {
    handleClick(value);
  };

  const confirmEnableActions = (modelName: string) => {
    const doorIds = selectedDoor.join('&ids=');
    door
      .doorActionsApi(modelName, doorIds)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch(
            setNotificationError({
              error: res?.data?.message,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: res?.data?.message,
              status: res?.data?.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const moreActionButton = [
    {
      title: t('remoteOpening'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteOpening'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="remoteOpen"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteClosing'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteClosing'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="remoteClose"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('enable'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('enable'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => confirmEnableActions('enable')}
                title={t('areYouWantToPerformTheEnableOperation')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('disable'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('disable'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => confirmEnableActions('disable')}
                title={t('areYouWantToPerformTheDisableOperation')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('cancelAlarm'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('cancelAlarm'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="cancelAlarm"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('remoteNormallyOpen'),
      clickFunction: (): void => {
        if (selectedDoor.length > 0) {
          setModalView({
            title: t('remoteNormallyOpen'),
            content: (
              <CommonPopup
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedIds={selectedDoor}
                inputTrue
                modelName="normalOpenDoor"
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      dropdown: (
        <ListDropdown
          data={options}
          label={t('lockDown&timezone')}
          changeFunction={changeValue}
          disabled={selectedData.length > 0}
        />
      ),
      clickFunction: handleClick,
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (doors.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('door'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDoor')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedDoor}
          getSelectedData={(val: any): any => setSelectedDoor(val)}
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          isFetching={status === 'loading'} // Placeholder status
          values={doors} // Use the actual data here
          fixedColumn={['doorName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Door;

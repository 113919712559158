/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import reader from '../../utils/api/Devices/reader';

export const fetchReaderList = createAsyncThunk(
  'device/fetchReaderList ',
  async (data: any, { dispatch }) => {
    const response = await reader
      .getReaders(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

interface ReaderReduxInterface {
  readers: any; // Assuming that the 'wiegand' array contains objects of type 'Wiegantformat'
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  readers: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const readerSlice: any = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    editReader: (state, action: any) => {
      if (state.readers.find((val: any) => val.id === action.payload.id)) {
        state.readers = state.readers.map((val: any) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
          }
          return output;
        });
      }
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchReaderList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchReaderList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.readers = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo += 1;
        }
        state.status = 'success';
      })
      .addCase(fetchReaderList.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, editReader } = readerSlice.actions;

export default readerSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
// Import card slice actions
import { fetchDeviceList, setSearchFilter } from '../../redux/slices/DeviceSlice';
import AddFirstPerson from './components/AddFirstPerson';
import AddPersonnel from './components/AddPersonnel';

function FirstPersonNormallyOpen() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { devices, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.device,
  );
  const [selectedData, setSelectedData] = useState<string[]>([]); // Add state to hold selected cards
  console.log(selectedData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesslevels'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Add state to hold selected cards
  const handleEditOpen = (editData: any) => {
    console.log(editData);
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('addPersonnel'),
        content: <AddPersonnel closeModal={() => setModalOpen(false)} />,
      });
    }
  };

  const accessLevelColumnsField = [
    {
      accessorKey: 'doorName',
      header: t('doorName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.doorName}
        </div>
      ),
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
    },
    {
      accessorKey: 'passageModeTimeZone',
      header: t('passageModeTimeZone'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'personnelId',
      header: t('personnelId'),
    },
    {
      accessorKey: 'firstName',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'department',
      header: t('department'),
    },
  ];

  // Sample data for the access levels table (replace with actual data)
  const levelslist = [
    {
      doorName: '617002502',
      deviceName: '617002502',
      passageModeTimeZone: 'Full Day Access',
    },
    {
      doorName: '617002502',
      deviceName: '617002502',
      passageModeTimeZone: '24 - Hour Accessible',
    },
  ];

  // Sample data for the doors table (replace with actual data)
  const doorsList = [
    { personnelId: '1255', firstName: 'asd', department: 'Dep_Test', lastName: '2 to 10' },
  ];

  const fetchData = async (params: any) => {
    try {
      if (status === 'success') {
        dispatch(fetchDeviceList({ ...params }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={() => {
                console.log('Delete confirmed for id:', val.id);
                setModalOpen(false);
              }}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      function: (val: any) => handleEditOpen(val),
    },
  ];

  useEffect(() => {
    if (devices.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('firstPersonNormallyOpen'),
          },
        ]}
      />
      <CardBox
        header={t('firstPersonnelNormalOpen')}
        title={t('listOfDoors')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddFirstPerson closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={accessLevelColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          values={levelslist}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="header" style={{ padding: '20px' }}>
          <h4>{t('browsePersonnel')}</h4>
        </div>
        <div className="doorlist">
          <span className="sub-title">{t('listOfPersonnel')}</span>
        </div>
        <DataTable
          action={actionButtons}
          columns={doorColumnsField}
          values={doorsList}
          actionButtonStatus={!modalOpen}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default FirstPersonNormallyOpen;

import React, { useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import DraggableImage from './draggable-Image';
import DropContainer from './drop-container';
import { DragDropImagesProps, ImageTypeInterface } from '../../../../interface/map.interface';

function DragDropImages({
  backgroundImage,
  draggableImages = [],
  handleClickEvent,
}: DragDropImagesProps) {
  const [images, setImages] = useState<ImageTypeInterface[]>(draggableImages);
  const [dragStart, setDragStart] = useState(false);

  const moveImage = (id: number, newPosition: { x: number; y: number }) => {
    setImages((prevImages) =>
      prevImages.map((image) => (image.id === id ? { ...image, position: newPosition } : image)),
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, delta }: any = event;
    const id = parseInt(active.id, 10);

    if (delta) {
      const image = images.find((img) => img.id === id);
      if (image) {
        const newPosition = {
          x: image.position.x + delta.x,
          y: image.position.y + delta.y,
        };
        moveImage(id, newPosition);
      }
    }
    setDragStart(false);
  };

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={() => setDragStart(true)}>
      <DropContainer backgroundImage={backgroundImage}>
        {images.map((image: any) => (
          <DraggableImage
            key={image.id}
            id={image.id}
            src={image.src}
            position={image.position}
            data={image.data}
            handleClickEvent={handleClickEvent}
            dragStart={dragStart}
          />
        ))}
      </DropContainer>
    </DndContext>
  );
}

export default DragDropImages;

import { post } from '../base';

interface ListApiProps {
  pageNo: number;
  search?: string;
  sortField?: string;
  sortOrder?: string;
}
export default {
  getPersonList: (
    data: ListApiProps = {
      pageNo: 1,
    },
  ) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/accLevelByPerson/getList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  syncPersonLevel: (id: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/accLevelByPerson/syncPersonLevel?personIds=${id}`,
      {},
    );
  },
  getPersonSelectLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/accLevelByPerson/getPersonSelectLevel`, data, {
      pageNo: 1,
      pageSize: 50,
    });
  },
  getPersonLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/accLevelByPerson/getPersonLevel`, data, {
      pageNo: 1,
      pageSize: 50,
    });
  },
  addToLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/accLevelByPerson/addLevel`, {}, data);
  },
  deleteLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/accLevelByPerson/delLevel`, {}, data);
  },
};
